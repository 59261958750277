import React, { useEffect, useState } from 'react'
import './payment.css'
import VerifiedIcon from '@mui/icons-material/Verified';
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import hotelActions from '../../redux/hotel/actions';

export const HotelBookingConfirm = () => {
  // const { hotelBokking } = useSelector((state) => state.hotelReducer);
  const [hotelBookingDetails, setHotelBookingDetails] = useState(JSON.parse(localStorage.getItem('hotelBookingData')))
  const dispatch = useDispatch();

  const [roomGuestDetails, setRoomGuestDetails] = useState([])

  useEffect(() => {
    if (hotelBookingDetails) {
      setRoomGuestDetails(hotelBookingDetails?.roomBookDetails?.rooms)
      // console.log('booking condition',hotelBookingDetails?.roomBookDetails?.rooms)
    }
    return () => {
      dispatch({ type: hotelActions.RESET_HOTEL_STATE });
    }
  }, [hotelBookingDetails]);


  return (
    <>
      <div className='hotel_boocking_confirm_page'>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className='hotel_confirm_card'>
                <a href="#" className="card credentialing">
                  <div className="overlay"></div>
                  <div className='yor_booking_con'>
                    <div className="circle">
                      <VerifiedIcon />
                    </div>
                    <h3>Your Booking confirm</h3>
                    <p>check blow booking details</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='traveller_list hotel_confirm_pas_det'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-7 col-md-7 col-sm-12 col-12'>
              <div className='hotel_booking_tabel'>
                <div><Typography variant='h6' className='travel_head'>Room Booking Details</Typography></div>
                <table className="table">
                  <tbody>
                    <tr>
                      <th scope="row">Booking Status</th>
                      <td>{hotelBookingDetails?.status}</td>
                    </tr>
                    <tr>
                      <th scope="row">Supplier Confirmation No</th>
                      <td>{hotelBookingDetails?.supplierConfirmationNum}</td>
                    </tr>
                    <tr>
                      <th scope="row">Check In</th>
                      <td>{hotelBookingDetails?.roomBookDetails?.checkIn}</td>
                    </tr>
                    <tr>
                      <th scope="row">Check Out</th>
                      <td>{hotelBookingDetails?.roomBookDetails?.checkOut}</td>
                    </tr>
                    <tr>
                      <th scope="row">Booking Days</th>
                      <td>{hotelBookingDetails?.roomBookDetails?.days}</td>
                    </tr>
                    <tr>
                      <th scope="row">Net Price</th>
                      <td>{hotelBookingDetails?.roomBookDetails?.currency} {hotelBookingDetails?.roomBookDetails?.NetPrice}</td>
                    </tr>
                    <tr>
                      <th scope="row">Fare Type</th>
                      <td>{hotelBookingDetails?.roomBookDetails?.fareType}</td>
                    </tr>
                    <tr>
                      <th scope="row">Customer Email</th>
                      <td>{hotelBookingDetails?.roomBookDetails?.customerEmail}</td>
                    </tr>
                    <tr>
                      <th scope="row">Customer Phone</th>
                      <td>{hotelBookingDetails?.roomBookDetails?.customerPhone}</td>
                    </tr>
                    <tr>
                      <th scope="row">Cancellation Policy</th>
                      <td>{hotelBookingDetails?.roomBookDetails?.cancellationPolicy}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className='col-lg-5 col-md-5 col-sm-12 col-12'>
              <div><Typography variant='h6' className='travel_head'>Room Details</Typography></div>
              {roomGuestDetails?.map((val, index) => (
                <div className="wrapper-traveller" key={'roomGuestDetails' + index}>
                  <div className="right">
                    <div className="info">
                      <h3>Room : {index + 1}</h3>
                      <div className='roo_typs'>
                        <ul>
                          <li><span className='bld_txt'>Name :</span>  {val?.name}</li>
                          <li><span className='bld_txt'>BoardType :</span>  {val?.boardType}</li>
                          <li><span className='bld_txt'>Description :</span>  {val?.description}</li>
                        </ul>
                      </div>
                      <div className="info_data">
                        <div className="data">
                          <p>{val.paxDetails?.name[0]} {val.paxDetails?.name.length > 1 ? <>+ {val.paxDetails?.name.length - 1}</> : <></>} </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
