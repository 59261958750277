import React from 'react'
import { DropzoneArea } from 'material-ui-dropzone'
import { Editor } from "@tinymce/tinymce-react";
import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import placeholderDrop from '../../asset/images/logo-gallery.png'
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TimePicker from 'react-bootstrap-time-picker';
import { Multiselect } from "multiselect-react-dropdown";

class CreateBlogEditor extends React.Component {

  constructor(props) {
    super(props);
    this.state = { content: "" };
    this.state = {
      files: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);

    this.state = { time: 0 };
    this.state = {
      plainArray: ["Option 1", "Option 2", "Option 3", "Option 4", "Option 5"],
      objectArray: [
        { key: "Option 1", cat: "Group 1" },
        { key: "Option 2", cat: "Group 1" },
        { key: "Option 3", cat: "Group 1" },
        { key: "Option 4", cat: "Group 2" },
        { key: "Option 5", cat: "Group 2" },
        { key: "Option 6", cat: "Group 2" },
        { key: "Option 7", cat: "Group 2" }
      ],
      selectedValues: [
        { key: "Option 1", cat: "Group 1" },
        { key: "Option 2", cat: "Group 1" }
      ]
    };

    this.addItem = this.addItem.bind(this);
  }
  handleTimeChange(time) {
    this.setState({ time });
  }
  handleChangeDrop(files) {
    this.setState({
      files: files
    });
  }
  handleChange(content, editor) {
    this.setState({ content });
  }

  handleSubmit(event) {
    alert("Text was submitted: " + this.state.content);
    event.preventDefault();
  }
  addItem() {
    this.selectedValues.push({ key: "Option 3", cat: "Group 1" });
  }
  render() {
    const uploadeImage = 6;
    const { objectArray } = this.state;
    return (
      <form onSubmit={this.handleSubmit}>

        <Box sx={{ flexGrow: 1 }} className='upload_blog_details'>
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Box className='upload_blog_form'>
                  <Box className="form-row">
                    <Box className="form-group">
                      <input name="firstName" type="text" className='form-control' />
                    </Box>
                  </Box>
                  <Box className="form-row">
                    <Box className="form-group">
                      <Editor
                        apiKey="qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc"
                        value={this.state.content}
                        init={{
                          height: 350,
                          menubar: false
                        }}
                        onEditorChange={this.handleChange}
                      />
                    </Box>
                  </Box>
                  <Box className="form-row blog_bg_drop">
                    <Box className="form-group">
                      <Box className="blog_bg_drop_sec">
                        <img className='blog_bg_drop_sec_img' src={placeholderDrop} alt='img' />
                        <DropzoneArea
                          onChange={this.handleChangeDrop.bind(this)}
                          dropzoneText={'Upload a file or drag and drop'}
                        />
                      </Box>
                    </Box>
                    <Box className='set-background_sec'>
                      {[...Array(uploadeImage)].map((e, i) =>
                        <Box className='setUs_background'>
                          <img src={placeholderDrop} alt='img' />
                          <Box className='setUs_background_text'>
                            <Typography variant='h5'>abcd image name.jpg</Typography>
                            <Link to='/'>Set as background</Link>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className='date_gategory_section'>
                  <Box className='blog_preview_btn'>
                    <Button><VisibilityIcon />Preview</Button>
                  </Box>
                  <Box className='blog_date_time'>
                    <Box className='blog_postDate'>
                      <Form.Group>
                        <Form.Label>Post Date</Form.Label>
                        <Form.Control type="date" placeholder="Date of Birth" />
                      </Form.Group>
                    </Box>
                    <Box className='blog_postTime'>
                      <Form.Group>
                        <Form.Label>Post Time</Form.Label>
                        <TimePicker onChange={this.handleTimeChange} value={this.state.time} start="00:00" end="23:59" step={30} />
                      </Form.Group>
                    </Box>
                  </Box>
                  <Box className='blog_catagies_select'>
                    <Form.Group>
                      <Form.Label>Category</Form.Label>
                      <Multiselect
                        options={objectArray}
                        displayValue="key"
                        showCheckbox={true}
                      />
                    </Form.Group>

                  </Box>
                  <Box className='blog_tag_select '>
                    <Form.Group>
                      <Form.Label>Tag</Form.Label>
                      <Multiselect
                        options={objectArray}
                        displayValue="key"
                        showCheckbox={true}
                      />
                    </Form.Group>

                  </Box>
                  <Box className='create_blog_publish'>
                    <Button>publish</Button>
                  </Box>

                </Box>

              </Grid>
            </Grid>
          </Container>
        </Box>

      </form>
    );
  }
}

export default CreateBlogEditor;
