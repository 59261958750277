import actions from "./actions";

const initialState = {
  hotelSearchResult: [],
  hotelDetails: [],
  hotelFilterResult: null,
  hotelSearchCondition: [],
  hotelSessionId: '',
  hotelRoomRate: [],
  hotelSearchValues: null,
  hotelStaticContent: [],
  nearestHotels: [],
  hotelPayment: '',
  hotelBokking: [],
  tempHotelDetails: null,
  reviews: [],
  reviewLoader: false,
  hotelFilterResult: null,
  hotelImages: [],
  hotelImageLoader: false,
  hotelClearFilter: false
};

const hotelReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_CLEAR_FILTER:
      return {
        ...state,
        hotelClearFilter: action.payload
      }
    case actions.HOTEL_IMAGE_LOADER:
      return {
        ...state,
        hotelImageLoader: action.payload
      }
    case actions.SET_NEXT_TOKEN:
      return {
        ...state,
        nextToken: action.payload
      }
    case actions.SET_HOTEL_IMAGES:
      return {
        ...state,
        hotelImages: action.payload
      }
    case actions.SET_COUPONS:
      return {
        ...state,
        hotelCoupons: action.payload
      }
    case actions.SET_REVIEW_LOADER:
      return {
        ...state,
        reviewLoader: action.payload
      }
    case actions.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload
      }
    case actions.RESET_HOTEL_STATE:
      return {
        ...initialState
      }
    case actions.SET_HOTEL_REVIEWS:
      return {
        ...state,
        reviews: action.payload,
        nextPageUrl: action.page
      }
    case actions.SET_TEMP_HOTEL_DETAILS:
      return {
        ...state,
        tempHotelDetails: action.payload
      }
    case actions.SET_NEAREST_HOTELS:
      return {
        ...state,
        nearestHotels: action.payload
      }
    case actions.SET_HOTEL_STATIC_CONTENT:
      return {
        ...state,
        hotelStaticContent: action.payload
      }
    case actions.SET_HOTEL_SEARCH:
      return {
        ...state,
        hotelSearchResult: action.payload
      }
    case actions.SET_HOTEL_DETAILS:
      return {
        ...state,
        hotelDetails: action.payload.result,
        initialHotelDetails: action.payload.initialDetails,
      }
    case actions.SET_HOTEL_FILTER:
      return {
        ...state,
        hotelFilterResult: action.payload
      }
    case actions.GET_HOTEL_SEARCH:
      return {
        ...state,
        hotelSearchCondition: action.payload
      }
    case actions.SET_HOTEL_BOOKING:
      return {
        ...state,
        hotelBokking: action.payload
      }
    case actions.SET_HOTEL_SESSION_DATE:
      return {
        ...state,
        hotelSessionDate: action.payload
      }
    case actions.SET_HOTEL_SESSIONID:
      return {
        ...state,
        hotelSessionId: action.payload
      }
    case actions.SET_HOTEL_RATE:
      return {
        ...state,
        hotelRoomRate: action.payload.result,
      }
    case actions.SET_HOTEL_SEARCH_VALUE:
      return {
        ...state,
        hotelSearchValues: action.payload
      }
    case actions.SET_HOTEL_PAYMENT:
      return {
        ...state,
        hotelPayment: action.payload
      }
    case actions.SET_DETAIL_PAGE_BOOKING:
      return {
        ...state,
        detailPageBooking: action.payload
      }
    case actions.SET_DETAIL_PAGE_BOOKING_CONDISION:
      return {
        ...state,
        bookingConfirmation: action.payload
      }
    case actions.RESET_HOTEL_REDUCER:
      return {
        ...action.payload,
      }
    default:
      return state;
  }
};

export default hotelReducer;