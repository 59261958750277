import { Box } from '@material-ui/core'
import React from 'react'
import Header from '../components/common/header'
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import blogBanner1 from '../asset/hotel/1.jpg'
import { Typography, Button, Container } from '@mui/material';
import Grid from '@material-ui/core/Grid';
import { BlogHorizontalCard } from '../components/Blog/BlogHorizontalCard';
import BlogVerticalCard from '../components/Blog/BlogVerticalCard';
import { BlogersCard } from '../components/Blog/BlogersCard';
import OurExperience from '../components/home/OurExperience'
import Footer from '../components/common/footer'
import BlogCountryCard from '../components/Blog/BlogCountryCard';

const optionsBloggers = {
    margin: 10,
    responsiveClass: true,
    nav: true,
    loop: true,
    dots: false,
    autoplay: false,
    navText: ["<", ">"],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 2,
        },
        600: {
            items: 3,
        },
        700: {
            items: 4,
        },
        1000: {
            items: 7,
        }
    },
};

const optionsSlider = {
    margin: 10,
    responsiveClass: true,
    nav: true,
    loop: true,
    dots: false,
    autoplay: false,
    navText: ["<", ">"],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        700: {
            items: 1,
        },
        1000: {
            items: 1,
        }
    },
};
const countryCard = 4;
const horizontalCard = 4;
const blogCard = 6;

export const BlogLogin = () => {
    return (
        <Box className='blog_section'>
            <Header />
            <Box className='blog_banner'>
                <OwlCarousel className="owl-theme blogBanner" {...optionsSlider}>
                    <Box className="item blogBannerInner">
                        <img src={blogBanner1} alt='img' />
                        <div className='overlay'></div>
                        <Box className='blogBannerInner_content'>
                            <Typography variant='h2'>Discover - Thailand</Typography>
                            <Typography variant='span'>10 Easy Way to be Environmentally Conscious At Thailand</Typography>
                        </Box>
                    </Box>
                    <Box className="item blogBannerInner">
                        <img src={blogBanner1} alt='img' />
                        <div className='overlay'></div>
                        <Box className='blogBannerInner_content'>
                            <Typography variant='h2'>Discover - Thailand</Typography>
                            <Typography variant='span'>10 Easy Way to be Environmentally Conscious At Thailand</Typography>
                        </Box>
                    </Box>
                    <Box className="item blogBannerInner">
                        <img src={blogBanner1} alt='img' />
                        <div className='overlay'></div>
                        <Box className='blogBannerInner_content'>
                            <Typography variant='h2'>Discover - Thailand</Typography>
                            <Typography variant='span'>10 Easy Way to be Environmentally Conscious At Thailand</Typography>
                        </Box>
                    </Box>
                    <Box className="item blogBannerInner">
                        <img src={blogBanner1} alt='img' />
                        <div className='overlay'></div>
                        <Box className='blogBannerInner_content'>
                            <Typography variant='h2'>Discover - Thailand</Typography>
                            <Typography variant='span'>10 Easy Way to be Environmentally Conscious At Thailand</Typography>
                        </Box>
                    </Box>
                </OwlCarousel>
            </Box>
            <Box className='recent_blog'>
                <Container>
                    <Box className='recentNews_slider_section'>
                        <Grid container spacing={3}>
                            <Grid item lg={7} sm={12}>
                                <Box className='recent_blog_head'>
                                    <Typography variant='h3'>destination blog's</Typography>
                                    <Button className='book_tick blog_more'>View All</Button>
                                </Box>
                                <Grid container spacing={3}>
                                    {
                                        [...Array(countryCard)].map((e, i) =>
                                            <Grid item lg={6} sm={12}>
                                                <BlogCountryCard />
                                            </Grid>
                                        )
                                    }

                                </Grid>
                            </Grid>
                            <Grid item lg={5} sm={12}>
                                <Box className='recent_blog_head'>
                                    <Typography variant='h3'>suggested blog's</Typography>
                                    <Button className='book_tick blog_more'>View All</Button>
                                </Box>
                                {
                                    [...Array(horizontalCard)].map((e, i) =>
                                        <BlogHorizontalCard />
                                    )
                                }

                            </Grid>
                        </Grid>
                    </Box>
                </Container>
                <Box className='recent_news_overlay'></Box>
            </Box>
            <Box className='main_before_blog'>
                <Container>
                    <Grid container spacing={3}>

                        {[...Array(blogCard)].map((i, e) => <Grid item lg={4}><BlogVerticalCard /></Grid>)}
                        <Grid lg={12} className='al-c'>
                            <Button className='subscribe_btn al-c'>Explore More</Button>
                        </Grid>
                    </Grid>


                </Container>
            </Box>
            <Box className='sufferingOn_catogory'>
                <Container>
                    <Grid container spacing={3}>
                        <Grid item lg={12}>
                            <Typography variant='h3'>suffering on categories</Typography>
                        </Grid>
                        <Grid item lg={12}>
                            <Box className='catogories_btns'>
                                <Button type='button' className='catogories_btn_1'>Adventure</Button>
                                <Button type='button' className='catogories_btn_2'>Heritage</Button>
                                <Button type='button' className='catogories_btn_3'>Holiday</Button>
                                <Button type='button' className='catogories_btn_4'>pilgrimage</Button>
                                <Button type='button' className='catogories_btn_5'>travel</Button>
                                <Button type='button' className='catogories_btn_6'>wildlife</Button>
                                <Button type='button' className='catogories_btn_7'>culture</Button>
                            </Box>
                        </Grid>

                    </Grid>
                </Container>
            </Box>
            <Box className='blogers_section'>
                <Container>
                    <Box className='recent_blog_head'>
                        <Typography variant='h3'>bloggers</Typography>
                        <Button className='book_tick blog_more'>explore more</Button>
                    </Box>
                    <Box className='bloggers_slider'>
                        <OwlCarousel className="owl-theme bloggerSlider" {...optionsBloggers}>
                            <Box className="item bloggerInner">
                                <BlogersCard />
                            </Box>
                        </OwlCarousel>
                    </Box>
                </Container>
            </Box>
            <OurExperience />
            <Footer />
        </Box>
    )
}
