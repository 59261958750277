import OurExperience from '../components/home/OurExperience'
import PopularPlace from '../components/home/PopularPlace'
import Testimonial from '../components/home/Testimonial'
import TravelSupport from '../components/home/TravelSupport'
import MenuTab from '../components/home/MenuTab';

export default function Home() {

  return (
    <>
        <div className="banner_overlay"></div>
      <div className='home_flight_search home_hotel_search '>
        <MenuTab />
      </div>
      <PopularPlace />
      <TravelSupport />
      <OurExperience />
      <Testimonial />
    </>
  )
}
