import { Box } from '@material-ui/core'
import React from 'react'
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import blogBannerRecentNews1 from '../../asset/hotel/1.jpg'
import { Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import avatarImg from '../../asset/images/avatar.jpg'
import { AvatarGroup } from '@material-ui/lab';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

const optionsSlider = {
  margin: 10,
  responsiveClass: true,
  nav: false,
  loop: true,
  dots: true,
  autoplay: false,
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 1,
    },
    700: {
      items: 1,
    },
    1000: {
      items: 1,
    }
  },
};

export const BlogRecentNewsBanner = () => {
  return (
    <div>
      <Box className='blog_recentNews_banner'>
        <OwlCarousel className="owl-theme blogBannerRecentNews" {...optionsSlider}>
          <Box className="item blogRecentNews">
            <Box className='blogBannerRecentNews_tag'>
              <LocalOfferIcon />
            </Box>
            <img className='blogRecentNews_img' src={blogBannerRecentNews1} alt='img' />
            <Box className='recent_bennr_head'>
              <Typography className='recent_bennr_head_txt' variant='h5'>how to give your space a parisian - inspired makeover</Typography>
              <Box className='RecentNewsInner_content'>
                <div className='overlay'></div>
                <Box className='recent_banne_blogers'>
                  <Stack direction="row" spacing={2}>
                    <Avatar className='blogeSingle_img' alt="Remy Sharp" src={avatarImg} />
                    <Box className='recent_banne_name'>
                      <Typography variant='h6'>Bloger Name</Typography>
                      <Typography variant='span' className='date_txt'>27 august</Typography>
                    </Box>
                  </Stack>
                </Box>
                <Box className='recent_banne_blogers bloger_like_share bf-l'>
                  <Box direction="row" className='recent_banne_name'>
                    <Typography variant='span'>Liked by</Typography>
                    <Typography variant='h5'>Muthu and 52 others</Typography>
                  </Box>
                </Box>
                <Box className='recent_banne_blogers bloger_like_share af-l'>
                  <AvatarGroup >
                    <Avatar alt="Remy Sharp" src={avatarImg} />
                    <Avatar alt="Travis Howard" src={avatarImg} />
                    <Avatar alt="Cindy Baker" src={avatarImg} />
                  </AvatarGroup>
                </Box>
                <Box className='recent_banne_blogers'>
                  <FavoriteIcon className='red' />
                  <ShareIcon />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="item blogRecentNews">
            <Box className='blogBannerRecentNews_tag'>
              <LocalOfferIcon />
            </Box>
            <img className='blogRecentNews_img' src={blogBannerRecentNews1} alt='img' />
            <Box className='recent_bennr_head'>
              <Typography className='recent_bennr_head_txt' variant='h5'>how to give your space a parisian - inspired makeover</Typography>
              <Box className='RecentNewsInner_content'>
                <div className='overlay'></div>
                <Box className='recent_banne_blogers'>
                  <Stack direction="row" spacing={2}>
                    <Avatar className='blogeSingle_img' alt="Remy Sharp" src={avatarImg} />
                    <Box className='recent_banne_name'>
                      <Typography variant='h6'>Bloger Name</Typography>
                      <Typography variant='span' className='date_txt'>27 august</Typography>
                    </Box>
                  </Stack>
                </Box>
                <Box className='recent_banne_blogers bloger_like_share bf-l'>
                  <Box direction="row" className='recent_banne_name'>
                    <Typography variant='span'>Liked by</Typography>
                    <Typography variant='h5'>Muthu and 52 others</Typography>
                  </Box>
                </Box>
                <Box className='recent_banne_blogers bloger_like_share af-l'>
                  <AvatarGroup >
                    <Avatar alt="Remy Sharp" src={avatarImg} />
                    <Avatar alt="Travis Howard" src={avatarImg} />
                    <Avatar alt="Cindy Baker" src={avatarImg} />
                  </AvatarGroup>
                </Box>
                <Box className='recent_banne_blogers'>
                  <FavoriteIcon className='red' />
                  <ShareIcon />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="item blogRecentNews">
            <Box className='blogBannerRecentNews_tag'>
              <LocalOfferIcon />
            </Box>
            <img className='blogRecentNews_img' src={blogBannerRecentNews1} alt='img' />
            <Box className='recent_bennr_head'>
              <Typography className='recent_bennr_head_txt' variant='h5'>how to give your space a parisian - inspired makeover</Typography>
              <Box className='RecentNewsInner_content'>
                <div className='overlay'></div>
                <Box className='recent_banne_blogers'>
                  <Stack direction="row" spacing={2}>
                    <Avatar className='blogeSingle_img' alt="Remy Sharp" src={avatarImg} />
                    <Box className='recent_banne_name'>
                      <Typography variant='h6'>Bloger Name</Typography>
                      <Typography variant='span' className='date_txt'>27 august</Typography>
                    </Box>
                  </Stack>
                </Box>
                <Box className='recent_banne_blogers bloger_like_share bf-l'>
                  <Box direction="row" className='recent_banne_name'>
                    <Typography variant='span'>Liked by</Typography>
                    <Typography variant='h5'>Muthu and 52 others</Typography>
                  </Box>
                </Box>
                <Box className='recent_banne_blogers bloger_like_share af-l'>
                  <AvatarGroup >
                    <Avatar alt="Remy Sharp" src={avatarImg} />
                    <Avatar alt="Travis Howard" src={avatarImg} />
                    <Avatar alt="Cindy Baker" src={avatarImg} />
                  </AvatarGroup>
                </Box>
                <Box className='recent_banne_blogers'>
                  <FavoriteIcon className='red' />
                  <ShareIcon />
                </Box>
              </Box>
            </Box>
          </Box>

        </OwlCarousel>
      </Box>
    </div>
  )
}
