import { Box, Container, Typography } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../common/footer'
import Header from '../common/header'
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import avatar from '../../asset/images/avatar.jpg'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import storyImage from '../../asset/images/blog-3.png'
import AddImage from '../../asset/images/add.jpg'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useState } from 'react'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const storyCard = 6;
const followers = 5;

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .required('Title is required'),
  name: Yup.string()
    .required('Name is required'),
  lastName: Yup.string()
    .required('Last name is required'),
  dob: Yup.string()
    .required('Date of Birth is required')
    .matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, 'Date of Birth must be a valid date in the format YYYY-MM-DD'),
  email: Yup.string()
    .required('Email is required')
    .email('Email is invalid'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
  acceptTerms: Yup.bool()
    .oneOf([true], 'Accept Ts & Cs is required')
});

export const CreatedBlogList = () => {

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = (data) => {
    alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
    return false;
  }

  return (
    <Box className='create_blog_section'>
      <Header />
      <Box className='create_blog_head'>
        <Container>
          <ul>
            <li><Link to=''>create new blog</Link></li>
            <li><Link className='active' to=''>blog list</Link></li>
          </ul>
        </Container>
      </Box>
      <Box className='blog_user_profile_section'>
        <Box className='blog_user_profile'>
          <Container>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={3}>
                  <Box className='blog_user_profile_card'>
                    <Box className='blog_user_profile_card_img'>
                      <img src={avatar} alt='img' />
                    </Box>
                    <Box className='blog_user_profile_content'>
                      <Typography variant='h5'>mark antony</Typography>
                      <Typography className='blogs_count' variant='span'>180 Blogs</Typography>
                      <Typography variant='h6'>85 Followers</Typography>
                      <FormControlLabel control={<Switch defaultChecked />} label="Label" />
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={9}>
                  <Box className='blog_user_personal_details'>
                    <Box sx={{ width: '100%' }}>
                      <Box sx={{ borderBottom: 1, borderColor: 'Boxider' }}>
                        <Tabs value={value} onChange={handleChange} TabIndicatorProps={{
                          style: {
                            backgroundColor: "#172651"
                          }
                        }} aria-label="basic tabs example">
                          <Tab label="Personal data" {...a11yProps(0)} />
                          <Tab label="Other's" {...a11yProps(1)} />
                          <Tab label="settings" {...a11yProps(2)} />
                          <Tab label="password" {...a11yProps(3)} />
                          <Tab label="my points" {...a11yProps(4)} />
                        </Tabs>
                      </Box>
                      <TabPanel value={value} index={0} className='blog_personalDetails'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <Box>
                            <Grid container spacing={2}>
                              <Grid item lg={4}>
                                <Box className="form-group">
                                  <label>Name</label>
                                  <input name="name" type="text" {...register('name')} className={`form-control ${errors.name ? 'is-invalid' : ''}`} />
                                  <Box className="invalid-feedback">{errors.name?.message}</Box>
                                </Box>
                              </Grid>
                              <Grid item lg={4}>
                                <Box className="form-group">
                                  <label>Email</label>
                                  <input name="email" type="text" {...register('email')} className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
                                  <Box className="invalid-feedback">{errors.email?.message}</Box>
                                </Box>
                              </Grid>
                              <Grid item lg={4}>
                                <Box className="form-group">
                                  <label>Mobile</label>
                                  <input name="mobile" type="number" {...register('mobile')} className={`form-control ${errors.mobile ? 'is-invalid' : ''}`} />
                                  <Box className="invalid-feedback">{errors.mobile?.message}</Box>
                                </Box>
                              </Grid>
                              <Grid item lg={4}>
                                <Box className="form-group">
                                  <label>Date of Birth</label>
                                  <input name="dob" type="date" {...register('dob')} className={`form-control ${errors.dob ? 'is-invalid' : ''}`} />
                                  <Box className="invalid-feedback">{errors.dob?.message}</Box>
                                </Box>
                              </Grid>
                              <Grid item lg={4}>
                                <Box className="form-group">
                                  <label>Facebook</label>
                                  <input name="facebook" type="text" {...register('facebook')} className={`form-control ${errors.facebook ? 'is-invalid' : ''}`} />
                                  <Box className="invalid-feedback">{errors.facebook?.message}</Box>
                                </Box>
                              </Grid>
                              <Grid item lg={4}>
                                <Box className="form-group">
                                  <label>Twitter</label>
                                  <input name="twitter" type="text" {...register('twitter')} className={`form-control ${errors.twitter ? 'is-invalid' : ''}`} />
                                  <Box className="invalid-feedback">{errors.twitter?.message}</Box>
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <Box className="form-group">
                                  <button type="submit" className="btn btn-primary mr-1">Update profile</button>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>

                        </form>
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        Item Two
                      </TabPanel>
                      <TabPanel value={value} index={2}>
                        Item Three
                      </TabPanel>
                      <TabPanel value={value} index={3}>
                        Item Two
                      </TabPanel>
                      <TabPanel value={value} index={4}>
                        Item Three
                      </TabPanel>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
        <Box className='blog_yourStory_followers'>
          <Container>
            <Grid container spacing={2}>
              <Grid item lg={8}>
                <Box className='recent_blog_head'>
                  <Typography variant='h3'>Your Story</Typography>
                </Box>
                <Box className='blog_yourStory'>
                  <Grid container spacing={2}>
                    {[...Array(storyCard)].map((i, e) =>
                      <Grid item lg={3}>
                        <Box className='blog_yourStory_card'>
                          <img src={storyImage} alt='img' />
                          <Typography variant='h5'>India</Typography>
                        </Box>
                      </Grid>
                    )}
                    <Grid item lg={3}>
                      <Box className='blog_yourStory_card add_more'>
                        <img src={AddImage} alt='img' />
                        <Typography variant='h5'>India</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item lg={4}>
                <Box className='blog_followers'>
                  <Box className='recent_blog_head'>
                    <Typography variant='h3'>follower's</Typography>
                    <Link to='' className='viewAll_ul'>view all</Link>
                  </Box>
                  <Box className='blog_followers_lists'>
                    {[...Array(followers)].map((e, i) =>
                      <Box className='blog_followers_Datalist'>
                        <Box className='blog_followers_img_content'>
                          <Box className='blog_followers_img'>
                            <img src={storyImage} alt='img' />
                          </Box>
                          <Box className='blog_followers_name'>
                            <Typography variant='h6'>Muthukumar</Typography>
                            <Typography variant='span'>muthukumar@gmail.com</Typography>
                          </Box>
                        </Box>
                        <Box className='blog_followers_detailsMore'>
                          <MoreVertIcon />
                        </Box>
                      </Box>
                    )}


                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>

      <Footer />
    </Box>
  )
}
