import { Button } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Add, Delete, Edit } from '@material-ui/icons';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useForm, Controller } from 'react-hook-form';
import { components } from "react-select";
import travellerActions from '../../redux/traveller/actions';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Grid from '@mui/material/Grid';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import { API_URL } from '../../utils/constant';
import moment from 'moment';

const style1 = {
    control: (base, state) => ({
        ...base,
        outline: state.isFocused ? "1px solid black" : "1px solid #e8ebf3",
        border: state.isFocused ? "1px solid black" : "1px solid #e8ebf3",
        padding: "3px 0px",
        ':hover': {
            outline: "1px solid black",
            border: "1px solid black",
        },
        ':active': {
            outline: "1px solid black",
            border: "1px solid black",
        },
        ':focus': {
            outline: "1px solid black",
            border: "1px solid black",
        },
        ':focus-visible': {
            outline: "1px solid black",
            border: "1px solid black",
        },
        boxShadow: "none"
    })
};

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <i className="fas fa-search cursor-pointer"></i>
        </components.DropdownIndicator>
    );
};

const existingTraveller = [];

const SaveTraveller = (props) => {
    const validationSchema = yup.object().shape({
        title: yup.string()
            .required('Title is required'),
        area_code: yup.number(),
        name: yup.string().matches(
            /^[A-Za-z\\s]*$/,
            "first name should not contain spaces or numbers"
        ).required('First Name is required'),
        lname: yup.string().matches(
            /^[A-Za-z\\s]*$/,
            "Last name should not contain spaces or numbers"
        ).required('Last name is required'),
        gender: yup.string()
            .required('Gender is required'),
        email: yup.string()
            .required('Email is required')
            .email('Email is invalid'),
        phone: yup.string()
            .min(10, 'must 10 number')
            .max(10, 'must 10 number')
            .matches(
                /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                "Phone number is not valid"
            )
            .required('phone is required'),
        dob: yup.string()
            .required('Birthday is required'),
        type: yup.string()
            .required('Type is required'),
        passportNumber: yup.string()
            .required('PassportNumber is required'),
        nationality: yup.object()
            .required('Nationality is required'),
        country_code: yup.object()
            .required('Country Code is required'),
        issueCountry: yup.object()
            .required('Country is required'),
        expiryDate: yup.string()
            .required('ExpiryDate is required'),
    });
    const dispatch = useDispatch();
    const { register, handleSubmit, reset, control, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });
    const { travellerListData } = useSelector((state) => state.travellerReducer)
    const { tempFlightDetails } = useSelector((state) => state.flightReducer)

    const [openTravellerModel, setOpenTravellerModel] = useState("");
    const [minAgeLimit, setMinAgeLimit] = useState('')
    const [maxAgeLimit, setMaxAgeLimit] = useState('')
    const [correctAge, setCorrectAge] = useState()

    const OnAddTraveller = (data) => {
        if (openTravellerModel?.type === "Edit") {
            dispatch({
                type: travellerActions.EDIT_ADULT_TRAVELLER, payload: {
                    traveler_id: travellerListData[openTravellerModel?.Id]?.id,
                    title: data.title,
                    first_name: data.name,
                    last_name: data.lname,
                    gender: data.gender,
                    type: data.type,
                    email: data.email,
                    phone: data.phone,
                    dob: data.dob,
                    area_code: data.area_code,
                    passport: data.passportNumber,
                    nationality: data.nationality.id,
                    country_code: data.country_code.id,
                    issue_country: data.issueCountry.id,
                    expire_date: data.expiryDate
                }
            });
        } else {
            dispatch({
                type: travellerActions.ADD_ADULT_TRAVELLER, payload: {
                    title: data.title,
                    first_name: data.name,
                    last_name: data.lname,
                    gender: data.gender,
                    email: data.email,
                    type: data.type,
                    phone: data.phone,
                    dob: data.dob,
                    area_code: data.area_code,
                    passport: data.passportNumber,
                    nationality: data.nationality.id,
                    country_code: data.country_code.id,
                    issue_country: data.issueCountry.id,
                    expire_date: data.expiryDate,
                }
            });
        }
        reset({
            title: "",
            name: "",
            lname: "",
            gender: "",
            type: "Adult",
            email: "",
            phone: "",
            dob: "",
            passportNumber: "",
            nationality: "",
            country_code: "",
            area_code: "",
            issueCountry: "",
            expiryDate: "",
        });
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear() - 14;
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }
        today = yyyy + '-' + mm + '-' + dd;
        setMaxAgeLimit(today);
        setMinAgeLimit(null);
        setOpenTravellerModel("");
    }

    const handleTrvellerOpen = () => {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear() - 14;
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }
        today = yyyy + '-' + mm + '-' + dd;
        setMaxAgeLimit(today);
        setMinAgeLimit(null);
        reset({
            title: "",
            name: "",
            lname: "",
            gender: "",
            type: "Adult",
            email: "",
            phone: "",
            dob: "",
            passportNumber: "",
            nationality: "",
            country_code: "",
            area_code: "",
            issueCountry: "",
            expiryDate: "",
        })
        setOpenTravellerModel({ type: "Add", Id: "" })
    }

    const OnDeleteTraveller = (val, index) => {
        dispatch({
            type: travellerActions.DELETE_ADULT_TRAVELLER, payload: {
                traveler_id: travellerListData[index].id,
            }
        });
    }

    const OnEditTraveller = (data, index) => {
        setOpenTravellerModel({ type: "Edit", Id: index })
        reset({
            title: data.title,
            name: data.first_name,
            lname: data.last_name,
            gender: data.gender,
            type: data.type,
            email: data.email,
            phone: data.phone,
            dob: data.dob,
            passportNumber: data.passport,
            nationality: data.nationality,
            country_code: data.country_code,
            area_code: data.area_code,
            issueCountry: data.issue_country,
            expiryDate: data.expire_date,
        })
    }

    const loadOptions = async (inputValue, callback) => {
        if (inputValue.trim().length >= 1) {
            var form_data = new FormData();
            form_data.append('name', inputValue.trim());
            const result = await axios.post(
                `${API_URL}/countries`,
                form_data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
            );
            callback(result.data.message)
        } else {
            callback(null)
        }
    }

    const loadCountryOptions = async (inputValue, callback) => {
        if (inputValue.trim().length > 0) {
            var form_data = new FormData();
            form_data.append('name', inputValue.trim());
            const result = await axios.post(
                `${API_URL}/countries`,
                form_data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
            );
            callback(result.data.message)
        } else {
            callback(null)

        }
    }

    useEffect(() => {
        if (correctAge === 'Adult') {
            var today = new Date();
            var dd = today.getDate();
            var mm = today.getMonth() + 1;
            var yyyy = today.getFullYear() - 14;
            if (dd < 10) {
                dd = '0' + dd
            }
            if (mm < 10) {
                mm = '0' + mm
            }
            today = yyyy + '-' + mm + '-' + dd;
            setMaxAgeLimit(today)
            setMinAgeLimit(null)
        }
        if (correctAge === 'Child') {
            var todayCM = new Date();
            var ddCM = todayCM.getDate();
            var mmCM = todayCM.getMonth() + 1;
            var yyyyCM = todayCM.getFullYear() - 14;
            if (ddCM < 10) {
                ddCM = '0' + ddCM
            }
            if (mmCM < 10) {
                mmCM = '0' + mmCM
            }
            todayCM = yyyyCM + '-' + mmCM + '-' + ddCM;
            setMinAgeLimit(todayCM)

            var todayCMX = new Date();
            var ddCMX = todayCMX.getDate();
            var mmCMX = todayCMX.getMonth() + 1;
            var yyyyCMX = todayCMX.getFullYear() - 2;
            if (ddCMX < 10) {
                ddCMX = '0' + ddCMX
            }
            if (mmCMX < 10) {
                mmCMX = '0' + mmCMX
            }
            todayCMX = yyyyCMX + '-' + mmCMX + '-' + ddCMX;
            setMaxAgeLimit(todayCMX)
        }
        if (correctAge === 'Infant') {
            var todayI = new Date();
            var ddI = todayI.getDate();
            var mmI = todayI.getMonth() + 1;
            var yyyyI = todayI.getFullYear() - 2;
            if (ddI < 10) {
                ddI = '0' + ddI
            }
            if (mmI < 10) {
                mmI = '0' + mmI
            }
            todayI = yyyyI + '-' + mmI + '-' + ddI;
            setMinAgeLimit(todayI)
            setMaxAgeLimit(new Date().toISOString().split("T")[0])
        }

    }, [correctAge])

    return (
        <Box>
            <Box className='addTraveller_btn'>
                <Button disabled={(existingTraveller?.length) === parseInt(tempFlightDetails?.adult_flight)} onClick={handleTrvellerOpen}>Add Traveller<Add /></Button>
            </Box>
            {travellerListData.map((val, index) =>
                <Box className="wrapper-traveller" key={'travellerListData' + index}>
                    <Box className="right">
                        <Box className="info">
                            <h3>{val.title}.{val.first_name} {val.last_name}</h3>
                            <Box className="info_data">
                                <Box className="data">
                                    <h4>Phone</h4>
                                    <p>{val.phone}</p>
                                </Box>
                                <Box className="data">
                                    <h4>Email</h4>
                                    <p>{val.email}</p>
                                </Box>
                                <Box className="data">
                                    <h4>Gender</h4>
                                    <p>{val.gender}</p>
                                </Box>
                                <Box className="data">
                                    <h4>Type</h4>
                                    <p>{val.type}</p>
                                </Box>
                                <Box className="data">
                                    <h4>Passport No</h4>
                                    <p>{val.passport}</p>
                                </Box>
                                <Box className="data">
                                    <h4>Nationality</h4>
                                    <p>{val.nationality.name}</p>
                                </Box>
                                <Box className="data">
                                    <Box className="social_media">
                                        <ul>
                                            <li><Edit onClick={() => OnEditTraveller(val, index)} /></li><br />
                                            <li><Delete onClick={() => OnDeleteTraveller(val, index)} /></li>
                                        </ul>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
            <Box>
                <Modal
                    open={!!openTravellerModel?.type}
                    onClose={() => {
                        setOpenTravellerModel(false);
                        var today = new Date();
                        var dd = today.getDate();
                        var mm = today.getMonth() + 1;
                        var yyyy = today.getFullYear() - 14;
                        if (dd < 10) {
                            dd = '0' + dd
                        }
                        if (mm < 10) {
                            mm = '0' + mm
                        }
                        today = yyyy + '-' + mm + '-' + dd;
                        setMaxAgeLimit(today);
                        setMinAgeLimit(null);
                        reset({
                            title: "",
                            name: "",
                            lname: "",
                            gender: "",
                            type: "Adult",
                            email: "",
                            phone: "",
                            dob: "",
                            passportNumber: "",
                            nationality: "",
                            country_code: "",
                            area_code: "",
                            issueCountry: "",
                            expiryDate: "",
                        });
                    }
                    }
                    aria-labelledby="modal-modal-gender"
                    aria-describedby="modal-modal-description"
                    className='traveller_model'
                >
                    <Box className='travellerAddPop'>
                        <Box className="card-body">
                            <Box>
                                <form onSubmit={handleSubmit(OnAddTraveller)}>
                                    <Grid container>
                                        <div className='row'>
                                            <Grid item className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                                <Box className="form-group">
                                                    <label>Title</label>
                                                    <select name="title" {...register('title', { required: true })} className={`form-control ${errors.title ? 'is-invalid' : ''}`}>
                                                        <option value="">Choose Title</option>
                                                        <option value="Mr">Mr</option>
                                                        <option value="Miss">Miss</option>
                                                        <option value="Mrs">Mrs</option>
                                                        <option value="Lord">Lord</option>
                                                        <option value="Lady">Lady</option>
                                                        <option value="Inf">Inf</option>
                                                    </select>
                                                    <Box className="invalid-feedback">{errors.title?.message}</Box>
                                                </Box>
                                            </Grid>
                                            <Grid item className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                                <Box className="form-group ">
                                                    <label>First Name</label>
                                                    <input name="name" type="text"{...register('name')} className={`form-control ${errors.name ? 'is-invalid' : ''}`} />
                                                    <Box className="invalid-feedback">{errors.name?.message}</Box>
                                                </Box>
                                            </Grid>
                                            <Grid item className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                                <Box className="form-group ">
                                                    <label>Last Name</label>
                                                    <input name="lname" type="text" {...register('lname')} className={`form-control ${errors.lname ? 'is-invalid' : ''}`} />
                                                    <Box className="invalid-feedback">{errors.lname?.message}</Box>
                                                </Box>
                                            </Grid>
                                            <Grid item className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                                <Box className="form-group">
                                                    <label>Gender</label>
                                                    <select name="gender" {...register('gender', { required: true })} className={`form-control ${errors.gender ? 'is-invalid' : ''}`}>
                                                        <option value=""></option>
                                                        <option value="M">Male</option>
                                                        <option value="F">Female</option>
                                                    </select>
                                                    <Box className="invalid-feedback">{errors.gender?.message}</Box>
                                                </Box>
                                            </Grid>
                                            <Grid item className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                                <Box className="form-group">
                                                    <label>Type</label>
                                                    <select name="type" {...register('type', { required: true })} className={`form-control ${errors.type ? 'is-invalid' : ''}`} onChange={((e) => setCorrectAge(e.target.value))}>
                                                        <option value="">Select Type</option>
                                                        <option value="Adult">Adult</option>
                                                        <option value="Child">Child</option>
                                                        <option value="Infant">Infant</option>
                                                    </select>
                                                    <Box className="invalid-feedback">{errors.type?.message}</Box>
                                                </Box>
                                            </Grid>

                                            <Grid item className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                                <Box className="form-group">
                                                    <label>Email</label>
                                                    <input name="email" type="text" {...register('email', { required: true })} className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
                                                    <Box className="invalid-feedback">{errors.email?.message}</Box>
                                                </Box>
                                            </Grid>
                                            <Grid item className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                                <Box className="form-group">
                                                    <label>Date of Birth</label>
                                                    <input name="dob" type="date" {...register('dob', { required: true })} className={`form-control ${errors.dob ? 'is-invalid' : ''}`} min={minAgeLimit ? minAgeLimit : null} max={maxAgeLimit ? maxAgeLimit : null} />
                                                    <Box className="invalid-feedback">{errors.dob?.message}</Box>
                                                </Box>
                                            </Grid>
                                            <Grid item className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                                <Box className="form-group">
                                                    <label>Phone Code(optional)</label>
                                                    <input name="area_code" type="number" min='0' {...register('area_code')} className={`form-control ${errors.area_code ? 'is-invalid' : ''}`} />
                                                    <Box className="invalid-feedback">{errors.area_code?.message}</Box>
                                                </Box>
                                            </Grid>
                                            <Grid className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                                <Box className="form-group">
                                                    <label>Country Code</label>
                                                    <div className={`${errors.country_code?.message && 'required-Field'}`}>
                                                        <Controller
                                                            control={control}
                                                            render={({ field }) => {
                                                                const handleSelectChange = (selectedOption) => {
                                                                    field.onChange({ dial_code: selectedOption?.dial_code, name: selectedOption?.name, country_code: selectedOption?.country_code, id: selectedOption?.id });
                                                                };
                                                                return (
                                                                    <AsyncSelect
                                                                        {...field}
                                                                        className={`w-100 ${errors.country_code?.message && 'is-invalid'}`}
                                                                        inputRef={field.ref}
                                                                        styles={style1}
                                                                        defaultOptions={field.option}
                                                                        components={{ DropdownIndicator }}
                                                                        name={"country_code"}
                                                                        getOptionLabel={(e) => e.dial_code + "-" + e.name}
                                                                        getOptionValue={(e) => e.dial_code + "-" + e.name}
                                                                        loadOptions={loadCountryOptions}
                                                                        onChange={handleSelectChange}
                                                                    />
                                                                );
                                                            }}
                                                            name="country_code"
                                                        />
                                                    </div>
                                                    <Box className="invalid-feedback">{errors.country_code?.message}</Box>
                                                </Box>
                                            </Grid>
                                            <Grid item className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                                <Box className="form-group">
                                                    <label>Phone</label>
                                                    <input name="phone" type="number" min='0' {...register('phone', { required: true })} className={`form-control ${errors.phone ? 'is-invalid' : ''}`} />
                                                    <Box className="invalid-feedback">{errors.phone?.message}</Box>
                                                </Box>
                                            </Grid>
                                            <Grid className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                                <Box className="form-group">
                                                    <label>Issuing Country</label>
                                                    <div className={`${errors.issueCountry?.message && 'required-Field'}`}>
                                                        <Controller
                                                            control={control}
                                                            render={({ field }) => {
                                                                const handleSelectChange = (selectedOption) => {
                                                                    field.onChange({ dial_code: selectedOption?.dial_code, name: selectedOption?.name, country_code: selectedOption?.country_code, id: selectedOption?.id });
                                                                };
                                                                return (
                                                                    <AsyncSelect
                                                                        {...field}
                                                                        className={`w-100 ${errors.issueCountry?.message && 'is-invalid'}`}
                                                                        inputRef={field.ref}
                                                                        styles={style1}
                                                                        components={{ DropdownIndicator }}
                                                                        defaultOptions={field.option}
                                                                        name={"issueCountry"}
                                                                        getOptionLabel={(e) => e.name}
                                                                        getOptionValue={(e) => e.name}
                                                                        loadOptions={loadOptions}
                                                                        onChange={handleSelectChange}
                                                                    />
                                                                );
                                                            }}
                                                            name="issueCountry"
                                                        />
                                                    </div>
                                                    <Box className="invalid-feedback">{errors.issueCountry?.message}</Box>
                                                </Box>
                                            </Grid>
                                            <Grid className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                                <Box className="form-group">
                                                    <label>Nationality</label>
                                                    <div className={`${errors.nationality?.message && 'required-Field'}`}>
                                                        <Controller
                                                            control={control}
                                                            render={({ field }) => {
                                                                const handleSelectChange = (selectedOption) => {
                                                                    field.onChange({ dial_code: selectedOption?.dial_code, name: selectedOption?.name, country_code: selectedOption?.country_code, id: selectedOption?.id });
                                                                };
                                                                return (
                                                                    <AsyncSelect
                                                                        {...field}
                                                                        className={`w-100 ${errors.nationality?.message && 'is-invalid'}`}
                                                                        inputRef={field.ref}
                                                                        styles={style1}
                                                                        defaultOptions={field.option}
                                                                        components={{ DropdownIndicator }}
                                                                        name={"nationality"}
                                                                        getOptionLabel={(e) => e.name}
                                                                        getOptionValue={(e) => e.name}
                                                                        loadOptions={loadOptions}
                                                                        onChange={handleSelectChange}
                                                                    />
                                                                );
                                                            }}
                                                            name="nationality"
                                                        />
                                                    </div>
                                                    <Box className="invalid-feedback">{errors.nationality?.message}</Box>
                                                </Box>
                                            </Grid>
                                            <Grid className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                                <Box className="form-group">
                                                    <label>Passport Number</label>
                                                    <input name="passportNumber" type="text" {...register('passportNumber', { required: true })} className={`form-control ${errors.passportNumber ? 'is-invalid' : ''}`} />
                                                    <Box className="invalid-feedback">{errors.passportNumber?.message}</Box>
                                                </Box>
                                            </Grid>
                                            <Grid className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                                <Box className="form-group">
                                                    <label>Expiry Date</label>
                                                    <input name="expiryDate" type="date" {...register('expiryDate', { required: true })} min={moment(new Date()).add(1, "month").format('YYYY-MM-DD')} className={`form-control ${errors.expiryDate ? 'is-invalid' : ''}`} />
                                                    <Box className="invalid-feedback">{errors.expiryDate?.message}</Box>
                                                </Box>
                                            </Grid>
                                        </div>
                                        <Grid item lg={12}>
                                            <Box className="form-group addEdit_pop_btn">
                                                <button type="button" onClick={() => setOpenTravellerModel(false)} className="btn btn-secondary">Close</button>
                                                <button type="submit" className="btn btn-primary mr-1">{openTravellerModel?.type === "Edit" ? "Update" : "Add"}</button>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                </form>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            </Box>
        </Box>
    )
};

export default React.memo(SaveTraveller);
