import { Box } from '@material-ui/core'
import { Avatar, Stack, Typography } from '@mui/material'
import React from 'react'
import carImg from '../../asset/images/travel-2.png'
import avatarImg from '../../asset/images/avatar.jpg'
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ShareIcon from '@mui/icons-material/Share';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Link } from 'react-router-dom'

export const BlogHorizontalCard = () => {
  return (
    <div>
      <Link to='/blog-detail'>
        <Box className='blogHorizontal_card'>
          <Box className='blogHorizontal_card_img'>
            <img src={carImg} alt='img' />
          </Box>
          <Box className='blogHorizontal_card_content'>
            <Box className='horizontal_bager_name'>
              <Stack direction="row" spacing={2}>
                <Avatar alt="Remy Sharp" src={avatarImg} />
                <Box className='blogHorizontal_card_name'>
                  <Typography variant='h6'>Bloger Name</Typography>
                  <Typography variant='span' className='date_txt'>27 august</Typography>
                </Box>
              </Stack>
              <LocalOfferIcon />
            </Box>
            <Box className='horizontal_bager_td'>
              <Typography variant='h2'>
                American's Goverment get tested for a virus
              </Typography>
            </Box>
            <Box className='horizontal_bager_td bt-l'>
              <Box direction="row" className='horiCard_like_share'>
                <Typography variant='h5'><Typography variant='span'>Liked by</Typography>Muthu and 52 others</Typography>
              </Box>
              <Box className='horiCard_like_share_icon '>
                <FavoriteIcon />
                <ShareIcon />
              </Box>
            </Box>
          </Box>
        </Box>
      </Link>
    </div>
  )
}
