import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles, Typography } from '@material-ui/core';
import loginbackground from '../../asset/login/background.png'
import { Link } from 'react-router-dom';
import googleIcon from "../../asset/icons/google.png"
import facebookIcon from "../../asset/icons/facebook.png"
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useState } from "react";
import { useDispatch } from "react-redux";
import actions from '../../redux/user/actions'
import { useNavigate } from "react-router-dom";
import ToastAlert from '../common/ToastAlert';
import UserSlider from './UserSlider';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import axios from 'axios';
import { useEffect } from 'react';
import { API_URL } from '../../utils/constant';
import commonActions from '../../redux/common/actions';

const validationSchema = Yup.object().shape({
  usermailPhone: Yup.string()
    .required('mail / phone is required'),
  userpassword: Yup.string()
    .required('password is required'),
});

const useStyles = makeStyles((theme) =>
({
  loginSlide: {
    background: `url(${loginbackground})`,
    height: '100vh',
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.down("xs")]: {
      height: '100%',
      width: "100%",
      display: 'relative',
      paddingBottom: '2rem'
    },
  },

  brandlogo: {
    minHeight: ' 80px',
    maxHeight: ' 80px',
    margin: ' 3rem 0',
  },
  bannertxt: {
    fontSize: ' 16px',
    color: ' #fff',
    fontWeight: ' 300',
    lineHeight: ' 30px',
    width: ' 90%',
    margin: ' auto',
  },
  welcome_grid: {
    display: 'flex',
    justifyContent: ' space-around',
    alignItems: 'center',
    height: ' 100vh',
  },
  welcome_heading: {
    fontSize: '32px',
    fontWeight: ' 500',
  },
  welcome_icon: {
    width: ' 35px',
    margin: '0 0.5rem',
    padding: ' 8px',
    background: ' #eff2f9',
    boxShadow: ' 0px 0px 5px 1px #32323254',
    marginTop: ' 1.2rem',
    borderRadius: ' 5px',
  },
  welcom_social: {
    textAlign: 'center',
  },
})
);

const UserLogin = () => {

  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useNavigate();

  const { register, handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const [state, setState] = useState({
    googleLoginUrl: null,
    facebookURL: null,
  });

  const onSubmit = (data) => {
    dispatch({
      type: actions.USER_LOGIN, userData: {
        email: data.usermailPhone,
        password: data.userpassword
      },
      history: history
    });
  }

  const handleGoogle = async () => {
    let temp = {
      googleLoginUrl: null,
      facebookURL: null,
    };
    await axios.get(`${API_URL}/auth/google/url`)
      .then((response) => {
        temp = ({ googleLoginUrl: response.data.url, facebookURL: temp.facebookURL })
        dispatch({ type: commonActions.SET_LOADER, payload: true });
      })
      .catch((error) => {

      });
    await axios.get(`${API_URL}/auth/facebook/url`)
      .then((response) => {
        temp = { facebookURL: response.data.url, googleLoginUrl: temp.googleLoginUrl };
        setState(temp);
        dispatch({ type: commonActions.SET_LOADER, payload: true });
      })
      .catch((error) => {

      });
  }

  useEffect(() => {
    handleGoogle();
  }, []);

  // 776000&to=776002
  // 1373635
  //853762
  // 636000

  // $from = 774173;
  // $to = 774300;
  console.log(state)
  return (
    <div>
      <ToastAlert />
      <Box >
        <Grid className='row'>
          <Grid className='col-lg-5 col-md-5 col-sm-12 col-12 user_slider'>
            <UserSlider />
          </Grid>
          <Grid className="col-lg-7 col-md-7 col-sm-12 col-12 log_in">
            <Link to='/' className='back_home dn-m'><ReplyAllIcon />Back to home</Link>
            <div className={classes.welcome_grid}>
              <div className={classes.welcome_heade}>
                <Typography className={classes.welcome_heading} variant='h1'>Welcome to TickaTrip</Typography>
                <div className={classes.welcom_social}>
                  <a href={state.googleLoginUrl}><img className={classes.welcome_icon} src={googleIcon} alt="" /></a>
                  <a href={state.facebookURL}><img className={classes.welcome_icon} src={facebookIcon} alt="" /></a>
                </div>
                <div className='ortextcon'>
                  <h3 className='ortext'>
                    <span>Or</span>
                  </h3>
                </div>
                <div className='login_form login_user'>
                  <form className="form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                      <label htmlFor="exampleInputEmail1" className="form-label">Email | Phone</label>
                      <input type="text" className="form-control" aria-describedby="emailHelp" placeholder='Enter the email/phone'
                        name='usermailPhone'
                        {...register('usermailPhone')} />
                      <div className='error'>{errors.usermailPhone?.message}</div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                      <input type="password" className="form-control" id="exampleInputPassword1" placeholder='Enter the Password'
                        name='userpassword'
                        {...register('userpassword')} />
                      <div className='error'>{errors.userpassword?.message}</div>
                    </div>
                    <Box className='forgrt_and_otp'>
                      <Link className='txt_field_forget' to='/forget-mail'>Forgot Password</Link>
                    </Box>
                    <div className='form_btns'>
                      <button className='login_btn' type='submit'>SIGN IN</button>
                      <p className='signup_btn' >Dont have an account?<Link to='/signup'>Sign up</Link></p>
                      <div className='ortextcon'>
                        <h3 className='ortext'>
                          <span>Or</span>
                        </h3>
                      </div>
                      <Link className='txt_field_forget' to='/'>CONTINUE AS A GUEST</Link>
                    </div>
                  </form>
                </div>
              </div>

            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

export default UserLogin