import React, { useEffect, useState } from 'react'
import '../Hotel/payment.css'
import { useSelector } from 'react-redux';

export const HotelBookingDetail = () => {

    const { hotelBookingDetail } = useSelector((state) => state.userReducer)

    const [roomGuestDetails, setRoomGuestDetails] = useState([]);

    useEffect(() => {
        if (hotelBookingDetail) {
            setRoomGuestDetails(hotelBookingDetail?.roomBookDetails?.rooms)
        }
    }, [hotelBookingDetail]);

    return (
        <>
            <div className='traveller_list hotel_confirm_pas_det'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-7'>
                            <div className='hotel_booking_tabel'>
                                <div>
                                    <h5 className="MuiTypography-root MuiTypography-h5 travel_head">
                                        Room Booking Details
                                    </h5>
                                </div>
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <th scope="row">Booking Status</th>
                                            <td>{hotelBookingDetail?.status}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Booking Time</th>
                                            <td>{hotelBookingDetail?.roomBookDetails?.bookingDateTime}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Supplier Confirmation No</th>
                                            <td>{hotelBookingDetail?.supplierConfirmationNum}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Hotel Name</th>
                                            <td>{hotelBookingDetail?.roomBookDetails?.hotelName}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Hotel Address</th>
                                            <td>{hotelBookingDetail?.roomBookDetails?.address}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">City</th>
                                            <td>{hotelBookingDetail?.roomBookDetails?.city}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Country</th>
                                            <td>{hotelBookingDetail?.roomBookDetails?.country}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Check In</th>
                                            <td>{hotelBookingDetail?.roomBookDetails?.checkIn}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Check Out</th>
                                            <td>{hotelBookingDetail?.roomBookDetails?.checkOut}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Booking Days</th>
                                            <td>{hotelBookingDetail?.roomBookDetails?.days}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Net Price</th>
                                            <td>{hotelBookingDetail?.roomBookDetails?.currency} {hotelBookingDetail?.roomBookDetails?.NetPrice}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Fare Type</th>
                                            <td>{hotelBookingDetail?.roomBookDetails?.fareType}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Customer Email</th>
                                            <td>{hotelBookingDetail?.roomBookDetails?.customerEmail}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Customer Phone</th>
                                            <td>{hotelBookingDetail?.roomBookDetails?.customerPhone}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Cancellation Policy</th>
                                            <td>{hotelBookingDetail?.roomBookDetails?.cancellationPolicy}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {hotelBookingDetail?.roomBookDetails?.image ?
                                <div className='hotel_images'>
                                    <div className='container'>
                                        <div className='row '>
                                            <div className='col-lg-6 clo-md-6 col-sm-12'>
                                                <img src={hotelBookingDetail?.roomBookDetails?.image} alt='img' />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                            }
                        </div>
                        <div className='col-lg-5'>
                            <div>
                                <h6 className="MuiTypography-root MuiTypography-h6 travel_head">
                                    Room  Details
                                </h6></div>
                            {roomGuestDetails?.map((val, index) => (
                                <div className="wrapper-traveller" key={'roomGuestDetails' + index}>
                                    <div className="right">
                                        <div className="info">
                                            <h3>Room : {index + 1}</h3>
                                            <div className='roo_typs'>
                                                <ul>
                                                    <li><span className='bld_txt'>Name :</span>  {val?.name}</li>
                                                    <li><span className='bld_txt'>BoardType :</span>  {val?.boardType}</li>
                                                    <li><span className='bld_txt'>Description :</span>  {val?.description}</li>
                                                </ul>
                                            </div>
                                            <div className="info_data">
                                                <div className="data">
                                                    <p>{val.paxDetails?.name[0]} {val.paxDetails?.name.length > 1 ? <>+ {val.paxDetails?.name.length - 1}</> : <></>} </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
