export const numberRestriction = (e) => {
    e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
    e.target.value = (e.target.value.indexOf(".") >= 0) ? (e.target.value.substr(0, e.target.value.indexOf(".")) +
        e.target.value.substr(e.target.value.indexOf("."), 3)) : e.target.value;
}

export const decimalRestriction = (e) => {
    e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
    e.target.value = (e.target.value.indexOf(".") >= 0) ? (e.target.value.substr(0, e.target.value.indexOf(".")) +
        e.target.value.substr(e.target.value.indexOf("."), 4)) : e.target.value;
}

export const negativeNumberRestriction = (e) => e.target.value = e.target.value.replace(/[^-.?0-9.]/g, '').replace(/(\..*)\./g, '$1');

