import { Box, Typography } from '@material-ui/core'
import React from 'react'
import avatarImg from '../../asset/images/avatar.jpg'

export const BlogersCard = () => {
  return (
    <div>
      <Box className='bloggerCard'>
        <img src={avatarImg} alt='img' />
        <Typography variant='h4'>Blogger Name</Typography>
        <Typography variant='span'>251 Blogs</Typography>
      </Box>
    </div>
  )
}
