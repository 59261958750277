import { AppBar, Box, Container, makeStyles, Toolbar } from '@material-ui/core'
import { Facebook, YouTube } from '@material-ui/icons'
import { Twitter } from '@mui/icons-material'
import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../asset/images/logo.png';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ToastAlert from './ToastAlert'
import { useDispatch, useSelector } from 'react-redux'
import Timer from './timer'
import hotelActions from '../../redux/hotel/actions'
import InstagramIcon from '@mui/icons-material/Instagram';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import userActions from '../../redux/user/actions';
import { Nav, NavDropdown, Navbar } from 'react-bootstrap';
import ProfileDefault from '../../asset/icons/profile_user.png';
import { PROFILE_URL } from '../../utils/profileConst';
import NavbarIcon from '@mui/icons-material/Sort';
import CloseIcon from '@mui/icons-material/Close';
const useStyles = makeStyles((theme) =>
({
    header: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0px'
    },
    appbar: {
        backgroundColor: '#fff',
        position: 'relative',
    },
    brandlogo: {
        minHeight: ' 60px',
        maxHeight: ' 60px',
    },
    menulink: {
        color: '#5a5a5a',
        textTransform: 'uppercase',
        fontSize: '14px',
        fontWeight: '500',
        position: 'relative',
        top: '0.4rem',
        marginLeft: '3rem',
    },
    nav: {
        height: 'auto',
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    navInner: {
        display: "flex",
    },
    phn: {
        color: '#4b4d4c',
        fontSize: '15px',
        fontWeight: '400',
        lineHeight: '3',
        borderRight: ' 1px solid #bfbfbf',
        paddingRight: '1rem',
    },
    mail: {
        color: '#4b4d4c',
        fontSize: '15px',
        lineHeight: '3',
        padding: ' 0 0 0 2rem',
        fontWeight: '500',
    },
    icons: {
        color: '#aaaaaa',
        fontSize: '14px',
        padding: ' 0 0 0 1.5rem',
        fontWeight: '500',
        position: ' relative',

    },
    navMail: {
        display: 'flex',
        justifyContent: " space-between",
        alignItems: " center",
        [theme.breakpoints.down("xs")]: {
            display: 'block',
            justifyContent: 'unset',
            textAlign: 'center',
            paddingBottom: '1rem',
        },
    },
    topMenu: {
        height: 'auto',
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        background: '#f1f5f5',
        fontSize: '15px',
        padding: "5px",
    },
    reg: {
        color: ' #fff',
        padding: '0.2rem 1rem',
        marginLeft: '1.5rem',
        fontSize: '13px',
        fontWeight: '200',
        background: '#0041f1',
        borderRadius: '35px',
        cursor: 'pointer'
    },
    toolbar: {
        padding: '0'
    }
})
)

function Header(props) {
    const history = useNavigate();
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = useState(null);
    const { userProfileData } = useSelector((state) => state.travellerReducer)
    const [userLoginMail, setUserLoginMail] = useState()
    const open = Boolean(anchorEl);
    const logout = () => {
        dispatch({ type: userActions.LOG_OUT, history: history });
    };
    const classes = useStyles();
    const [sideBar, setSideBar] = useState(false);

    const ShowSidebar = () => setSideBar(!sideBar)

    const handleClick = (e) => {
        e.preventDefault();
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (localStorage.getItem("bookingData")) {
        let temp = JSON.parse(localStorage.getItem('bookingData'));
        dispatch({ type: hotelActions.SET_HOTEL_BOOKING, payload: temp });
        localStorage.removeItem("bookingData")
    }
    if (localStorage.getItem("hotelBookingData")) {
        let temp = JSON.parse(localStorage.getItem('bookingData'));
        dispatch({ type: hotelActions.SET_HOTEL_BOOKING, payload: temp });
        localStorage.removeItem("bookingData")
    }

    useEffect(() => {
        if (localStorage.getItem('username')) {
            setUserLoginMail(localStorage.getItem('username'));
        } else {
            setUserLoginMail(localStorage?.getItem('email')?.substring(0, localStorage?.getItem('email').lastIndexOf("@")));

        }
    }, [localStorage.getItem('email')])

    return (
        <div >
            <ToastAlert />
            <div className='mobileNone'>
                <div className={classes.topMenu}>
                    <Container>
                        <div className={classes.navMail} >
                            {/* <div className={classes.maillink}>
                            <a href='tel:+919876543210' className={classes.phn}>+91-98765-43210</a>
                            <a href='mailto:info@tickatrip.com' className={classes.mail}>info@tickatrip.com</a>
                        </div> */}
                            <Link to='/'>
                                <img className={classes.brandlogo} src={Logo} alt='img'></img>
                            </Link>
                            <div className='maillinkbtn' >
                                <a target='blank' className={classes.icons} href="https://www.facebook.com/login/" underline="none"><Facebook /></a>
                                <a target='blank' className={classes.icons} href="https://twitter.com/i/flow/login" underline="none"><Twitter /></a>
                                <a target='blank' className={classes.icons} href="https://www.youtube.com/" underline="none"><YouTube /></a>
                                <a target='blank' className={classes.icons} href="https://www.instagram.com/accounts/login/" underline="none"><InstagramIcon /></a>
                                <Timer mode={props.mode} />
                                <div className='cancelBookingBtn'>
                                    <Link to='/trip-cancel'><MenuItem className='cancelbtn'>Cancel Bookings</MenuItem></Link>
                                </div>
                                {localStorage.getItem('tickatrip-token') ?
                                    <div className='user_menu'>
                                        <Button
                                            id="basic-button"
                                            aria-controls={open ? 'basic-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            onClick={handleClick}
                                            className='user_profile_icon'
                                            endIcon={<KeyboardArrowDownIcon />}
                                        >
                                            <div className='webSiteProfile'>
                                                <AccountCircleIcon /><span className='pr-2'>{userLoginMail}</span>
                                            </div>
                                        </Button>
                                        <Menu
                                            id="basic-menu"
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                            }}
                                            className='user_menu_list'
                                        >
                                            <Link to='/profile'><MenuItem>Profile</MenuItem></Link>
                                            <Link to='/flight-booking'><MenuItem>My Flight Trip</MenuItem></Link>
                                            <Link to='/hotel-booking'><MenuItem>My Hotel Trip</MenuItem></Link>
                                            {/* <Link to='/forget-mail'><MenuItem>Forget Password</MenuItem></Link> */}
                                            <MenuItem onClick={logout}>Logout</MenuItem>
                                        </Menu>
                                    </div>
                                    :
                                    <div className='user_menu user_web_menu'>
                                        <span onClick={handleClick} className='sign_in_bt'>Sign in or Register</span>
                                        <Menu
                                            id="basic-menu"
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                            }}
                                            className='user_menu_list user_web_list'
                                        >
                                            <Link to='/login'><MenuItem>Login</MenuItem></Link>
                                            <Link to='/signup'><MenuItem>Register</MenuItem></Link>
                                        </Menu>
                                    </div>

                                }
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
            <AppBar className={classes.appbar}>
                <div className="d-lg-none">
                    <Toolbar className={classes.toolbar}>
                        <Container className={classes.header}>
                            <Box className={classes.nav} >
                                <div className={classes.navInner} display="flex">
                                    <header className="header py-0">
                                        <Navbar bg="light" expand="lg">
                                            <div className='header-divider'>
                                                <div className='mobile_nav_icon' onClick={ShowSidebar}>
                                                    {/* <Navbar.Toggle aria-controls="navbarScroll" /> */}
                                                    <NavbarIcon className='nav_Icons' style={{ fontSize: 40 }} />
                                                </div>
                                                <div>
                                                    <Link to='/'>
                                                        <img className={classes.brandlogo} src={Logo} alt='img'></img>
                                                    </Link>
                                                </div>
                                                <div>
                                                    {localStorage.getItem('tickatrip-token') ?
                                                    <div className='user_menu'>
                                                    <Button
                                                        id="basic-button"
                                                        aria-controls={open ? 'basic-menu' : undefined}
                                                        aria-haspopup="true"
                                                        aria-expanded={open ? 'true' : undefined}
                                                        onClick={handleClick}
                                                        className='user_profile_icon'
                                                        endIcon={<KeyboardArrowDownIcon />}
                                                    >
                                                        <div className='webSiteProfile'>
                                                            <AccountCircleIcon /><span className='pr-2'>{userLoginMail}</span>
                                                        </div>
                                                    </Button>
                                                    <Menu
                                                        id="basic-menu"
                                                        anchorEl={anchorEl}
                                                        open={open}
                                                        onClose={handleClose}
                                                        MenuListProps={{
                                                            'aria-labelledby': 'basic-button',
                                                        }}
                                                        className='user_menu_list'
                                                    >
                                                        <Link to='/profile'><MenuItem>Profile</MenuItem></Link>
                                                        <Link to='/flight-booking'><MenuItem>My Flight Trip</MenuItem></Link>
                                                        <Link to='/hotel-booking'><MenuItem>My Hotel Trip</MenuItem></Link>
                                                        {/* <Link to='/forget-mail'><MenuItem>Forget Password</MenuItem></Link> */}
                                                        <MenuItem onClick={logout}>Logout</MenuItem>
                                                    </Menu>
                                                </div>
                                                        // <div className='mobile_profile_view'>
                                                        //     <Button
                                                        //         id="basic-button"
                                                        //         aria-controls={open ? 'basic-menu' : undefined}
                                                        //         aria-haspopup="true"
                                                        //         aria-expanded={open ? 'true' : undefined}
                                                        //         onClick={handleClick}
                                                        //         className='user_profile_icon_btn'
                                                        //     // endIcon={<KeyboardArrowDownIcon />}
                                                        //     >
                                                        //         {/* <div className='mobileProfile'>
                                                        //             {(userProfileData?.profile_image === null || userProfileData?.profile_image === undefined) ?
                                                        //                 <img src={ProfileDefault} /> :
                                                        //                 <img src={userProfileData?.profile_image && `${PROFILE_URL}${userProfileData?.profile_image}`} alt={userProfileData?.username} />
                                                        //             }
                                                        //         </div> */}
                                                        //         <div className='webSiteProfile'>
                                                        //             <AccountCircleIcon /><span className='pr-2'>{userLoginMail}</span>
                                                        //         </div>
                                                        //     </Button>
                                                        //     <Menu
                                                        //         id="basic-menu"
                                                        //         anchorEl={anchorEl}
                                                        //         open={open}
                                                        //         onClose={handleClose}
                                                        //         MenuListProps={{
                                                        //             'aria-labelledby': 'basic-button',
                                                        //         }}
                                                        //         className='user_menu_list'
                                                        //     >
                                                        //         <Link to='/profile'><MenuItem>Profile</MenuItem></Link>
                                                        //         <Link to='/flight-booking'><MenuItem>My Flight Trip</MenuItem></Link>
                                                        //         <Link to='/hotel-booking'><MenuItem>My Hotel Trip</MenuItem></Link>
                                                        //         {/* <Link to='/forget-mail'><MenuItem>Forget Password</MenuItem></Link> */}
                                                        //         <MenuItem onClick={logout}>Logout</MenuItem>
                                                        //     </Menu>
                                                        // </div>
                                                        :
                                                        <div className='user_menu'>
                                                            <div className='mobileProfile' onClick={handleClick}>
                                                                {(userProfileData?.profile_image === null || userProfileData?.profile_image === undefined) ?
                                                                    // <img src={ProfileDefault} />
                                                                    <div className='user_menu user_web_menu'>
                                                                        <span onClick={handleClick} className='sign_in_bt'>Sign in or Register</span>

                                                                    </div>
                                                                    :
                                                                    <img src={userProfileData?.profile_image && `${PROFILE_URL}${userProfileData?.profile_image}`} alt={userProfileData?.username} />
                                                                }
                                                            </div>
                                                            <Menu
                                                                id="basic-menu"
                                                                anchorEl={anchorEl}
                                                                open={open}
                                                                onClose={handleClose}
                                                                MenuListProps={{
                                                                    'aria-labelledby': 'basic-button',
                                                                }}
                                                                className='user_menu_list'
                                                            >
                                                                <Link to='/login'><MenuItem>Sign In</MenuItem></Link>
                                                                <Link to='/signup'><MenuItem>Register</MenuItem></Link>
                                                            </Menu>
                                                        </div>

                                                    }
                                                </div>
                                            </div>
                                            {/* <Navbar.Collapse id="navbarScroll">
                                                <Nav
                                                    className="me-auto my-2 my-lg-0 px-2"
                                                    style={{ maxHeight: '200px' }}
                                                    navbarScroll
                                                >
                                                    <Nav.Link><Link to="/">HOME</Link></Nav.Link>
                                                    <Nav.Link><Link to="/hotel-list">HOTEL</Link></Nav.Link>
                                                    <Nav.Link><Link to="/flight">FLIGHT</Link></Nav.Link>
                                                    <Nav.Link><Link to="/trip-cancel">CANCEL BOOKINGS</Link></Nav.Link>

                                                </Nav>
                                            </Navbar.Collapse> */}

                                        </Navbar>

                                        {/* <input type="checkbox" id="nav" className="hidden" />
                                        <label htmlFor="nav" className="nav-toggle">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </label> */}

                                    </header>

                                </div>
                            </Box>
                        </Container>
                    </Toolbar>
                </div>
                {/* <div className='d-none d-lg-block my-3'>
                    <Toolbar>
                        <Container className={classes.header}>
                            <Link to='/'>
                                <img className={classes.brandlogo} src={Logo} alt='img'></img>
                            </Link>
                            <Box className={classes.nav} >
                                <div className={classes.navInner} display="flex" >
                                    <header className="header">
                                        <nav className="navbar">
                                            <input type="checkbox" id="nav" className="hidden" />
                                            <label htmlFor="nav" className="nav-toggle">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </label>
                                            <div className="wrapper">
                                                <ul className="menu">
                                                    <li className='menu-item'><Link to="/">HOME</Link></li>
                                                    <li className='menu-item'><Link to="/hotel-list">HOTEL</Link></li>
                                                    <li className='menu-item'><Link to="/bus">Bus</Link></li>
                                                    <li className='menu-item'><Link to="/flight">FLIGHT</Link></li>
                                                    <li className='menu-item'><Link to="/trip-cancel">CANCEL BOOKINGS</Link></li>
                                                    <li className='menu-item'><Link to="/trains">Trains</Link></li>
                                                    <li className='menu-item'><Link to="/contact">Contact</Link></li>
                                                </ul>
                                            </div>
                                        </nav>
                                    </header>
                                </div>

                            </Box>
                        </Container>
                    </Toolbar>

                </div> */}

            </AppBar>
            <div className={sideBar ? 'mobile_nav_bar active' : 'mobile_nav_bar'}>
                <Nav
                    className='backgroundStyle'
                    style={{ height: '100vh' }}
                    navbarScroll
                >
                    <span className='closeICons' onClick={ShowSidebar}><CloseIcon style={{ fontSize: 40 }} /></span>
                    <Nav.Link><Link onClick={ShowSidebar} to="/">HOME</Link></Nav.Link>
                    <Nav.Link><Link onClick={ShowSidebar} to="/hotel-list">HOTEL</Link></Nav.Link>
                    <Nav.Link><Link onClick={ShowSidebar} to="/flight">FLIGHT</Link></Nav.Link>
                    <Nav.Link><Link onClick={ShowSidebar} to="/trip-cancel">CANCEL BOOKINGS</Link></Nav.Link>

                </Nav>
            </div>
        </div>
    )
}

export default React.memo(Header);

