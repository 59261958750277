import { takeEvery, call, put, all } from 'redux-saga/effects';
import axios from 'axios';
import actions from './actions';
import { API_URL } from '../../utils/constant'
import travellerActions from './actions';
import commonActions from '../common/actions';


const travellerSaga = function* () {
    yield all([
        yield takeEvery(actions.ADD_ADULT_TRAVELLER, addAdultTraveller),
        yield takeEvery(actions.GET_TRAVELLER_LIST, getTravellerList),
        yield takeEvery(actions.EDIT_ADULT_TRAVELLER, editAdultTraveller),
        yield takeEvery(actions.DELETE_ADULT_TRAVELLER, deleteAdultTraveller),
        yield takeEvery(actions.GET_PROFILE_EDIT, profileEdit),
        yield takeEvery(actions.GET_PROFILE_DATA, userProfileData)
    ]);
};

const addAdultTraveller = function* (data) {
    const { payload } = data
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/user/addTraveler`, payload)
        );
        if (result.data.status) {
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message, status: "success" } });
        }
        yield put({ type: actions.GET_TRAVELLER_LIST });

    } catch (err) {
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: err.data.message, status: "failure" } });
    }
};


const getTravellerList = function* (data) {
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/user/getTravelers`)
        );
        if (result.data.status) {
            yield put({ type: actions.SET_TRAVELLER_LIST, payload: result.data.travelers });
        } else {
            yield put({ type: actions.SET_TRAVELLER_LIST, payload: [] });
        }
    } catch (err) {
        yield put({ type: actions.SET_TRAVELLER_LIST, payload: [] });
    }
};

const editAdultTraveller = function* (data) {
    const { payload } = data;
    var form_data = new FormData();
    for (var key in payload) {
        form_data.append(key, payload[key]);
    }
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/user/updateTraveler`,
                form_data)
        );
        if (result.data.status) {
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message, status: "success" } });
        }
        yield put({ type: actions.GET_TRAVELLER_LIST });
    } catch (err) {
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: err.data.message, status: "success" } });
        yield put({ type: actions.SET_TRAVELLER_EDIT, payload: [] });
    }
};

const deleteAdultTraveller = function* (data) {
    const { payload } = data;
    var form_data = new FormData();
    for (var key in payload) {
        form_data.append(key, payload[key]);
    }
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/user/deleteTraveler`,
                form_data)
        );
        if (result.data.status) {
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message, status: "success" } });
        }
        yield put({ type: actions.GET_TRAVELLER_LIST });
    } catch (err) {
        yield put({ type: actions.GET_TRAVELLER_LIST });
    }
};

const profileEdit = function* (data) {
    const { payload } = data;
    var form_data = new FormData();

    for (var key in payload) {
        form_data.append(key, payload[key]);
    }
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/user/updateProfile`,
                form_data)
        );
        yield put({ type: commonActions.SET_LOADER, payload: false });
        if (result.data.status) {
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message, status: "success" } });
        } else {
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.errors.profile_image.map((val) => <>{val}</>), status: "success" } });
        }
        yield put({ type: travellerActions.GET_PROFILE_DATA });

    } catch (err) {
        yield put({ type: travellerActions.GET_PROFILE_DATA });
        // yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.errors.profile_image.map((val) => <>{val}</>), status: "success" } });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    }
};

const userProfileData = function* (data) {
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/user`)
        );
        localStorage.setItem('email', result.data.email);
        localStorage.setItem('phone', result.data.phone);
        localStorage.setItem('username', result.data.username);
        yield put({ type: actions.SET_PROFILE_DATA, payload: result.data });
        yield put({ type: actions.GET_TRAVELLER_LIST })
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({ type: actions.SET_PROFILE_DATA, payload: [] });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    }
};
export default travellerSaga;