import React from 'react'
import Header from '../common/header'
import popularBanner from '../../asset/home-image/blog-3.png'
import { Box, Container, Grid, makeStyles, Typography } from "@material-ui/core";
import Footer from '../common/footer'
import BlogVerticalCard from './BlogVerticalCard';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const useStyles = makeStyles((theme) =>
({
    popularBanner: {
        backgroundImage: `url(${popularBanner})`,
        backgroundRepeat: 'no-repeat',
        width: ' 100%',
        height: '100%',
        minHeight: '45vh',
        maxHeight: '45vh',
        backgroundSize: ' cover',
        backgroundPosition: ' center center',
        position: ' relative',
    },
})
)
const blogListCard = 9;

export const BlogList = () => {

    const classes = useStyles();

    return (
        <Box className='popular_detail_sec'>
            <Header />
            <Box className={classes.popularBanner}>
                <Box className='overlay'></Box>
                <Typography variant='h4'>Thailand</Typography>
            </Box>

            <Box sx={{ flexGrow: 1 }} className='blog_list_section'>
                <Container>
                    <Grid container spacing={2}>
                        <Grid item lg={12}>
                            <Box className='recent_blog_head'>
                                <Typography variant='h3'>Thailand related blogs</Typography>
                            </Box>
                        </Grid>
                        <Grid item lg={12}>
                            <Grid container spacing={2}>
                                {[...Array(blogListCard)].map((i, e) =>
                                    <Grid item lg={4}>
                                        <BlogVerticalCard />
                                    </Grid>
                                )}

                            </Grid>
                        </Grid>
                        <Grid item lg={12}>
                            <Stack spacing={2} className='blog_list_pagination'>
                                <Pagination count={5} variant="outlined" shape="rounded" />
                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
            </Box>


            <Footer />
        </Box>
    )
}

