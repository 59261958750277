import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Box, Container, Typography } from '@material-ui/core';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import StarIcon from '@mui/icons-material/Star';
import trip1 from '../../asset/home-image/travel-1.png'
import trip2 from '../../asset/home-image/travel-2.png';
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    checked: {
        color: '#ffcc00',
    },
    relative: {
        position: 'relative',
    }
}));

const options = {
    margin: 10,
    responsiveClass: true,
    nav: true,
    loop: true,
    dots: false,
    autoplay: false,
    navText: ["<", ">"],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 2,
        },
        1000: {
            items: 4,
        }
    },
};

function OurExperience() {
    const classes = useStyles();
    return (
        <div className={classes.relative}>
            <Container>
                <Grid container spacing={3} className='ourexperience_area'>
                    <Grid item xs={12} sm={6} lg={6}>
                        <Box className='ourexperience_area_inner'>
                            <Typography variant='h2'>Our Experience</Typography>
                            <Typography variant='h5'>Explore the beauty of the world</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                        <Box className='ourexperience_area_inner'>
                            <Typography variant='inherit'>Contrary to popular belief, Lorem Ipsum is not simply random text.
                                It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.
                                Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia,
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12}>
                        <Grid className='experience_slider'>
                            <OwlCarousel className='owl-theme experienceSlide' {...options}>
                                <Link to='/'>
                                    <div className='item_experience_slider'>
                                        <img src={trip1} alt='' />
                                        <div className='experience_slider_content'>
                                            <Typography variant='h3'>A Surfing experience in the Maldives</Typography>
                                            <Box className='review'>
                                                <StarIcon className={classes.checked} />
                                                <StarIcon className={classes.checked} />
                                                <StarIcon className={classes.checked} />
                                                <StarIcon className={classes.checked} />
                                                <StarIcon />
                                                <Typography className='total_review' variant='inherit'>20 Reviews</Typography>
                                            </Box>
                                            <Box className='blog_tag'>
                                                <ul>
                                                    <li><span>blog</span></li>
                                                    <li><span>self ride</span></li>
                                                    <li><span>maldives</span></li>
                                                </ul>
                                            </Box>
                                        </div>
                                    </div>
                                </Link>

                                <Link to='/'>
                                    <div className='item_experience_slider'>
                                        <img src={trip1} alt='' />
                                        <div className='experience_slider_content'>
                                            <Typography variant='h3'>A Surfing experience in the Maldives</Typography>
                                            <Box className='review'>
                                                <StarIcon className={classes.checked} />
                                                <StarIcon className={classes.checked} />
                                                <StarIcon className={classes.checked} />
                                                <StarIcon className={classes.checked} />
                                                <StarIcon />
                                                <Typography className='total_review' variant='inherit'>20 Reviews</Typography>
                                            </Box>
                                            <Box className='blog_tag'>
                                                <ul>
                                                    <li><span>blog</span></li>
                                                    <li><span>self ride</span></li>
                                                    <li><span>maldives</span></li>
                                                </ul>
                                            </Box>
                                        </div>
                                    </div>
                                </Link>
                                <Link to='/'>
                                    <div className='item_experience_slider'>
                                        <img src={trip2} alt='' />
                                        <div className='experience_slider_content'>
                                            <Typography variant='h3'>A Surfing experience in the Maldives</Typography>
                                            <Box className='review'>
                                                <StarIcon className={classes.checked} />
                                                <StarIcon className={classes.checked} />
                                                <StarIcon className={classes.checked} />
                                                <StarIcon className={classes.checked} />
                                                <StarIcon />
                                                <Typography className='total_review' variant='inherit'>20 Reviews</Typography>
                                            </Box>
                                            <Box className='blog_tag'>
                                                <ul>
                                                    <li><span>blog</span></li>
                                                    <li><span>self ride</span></li>
                                                    <li><span>maldives</span></li>
                                                </ul>
                                            </Box>
                                        </div>
                                    </div>
                                </Link>
                            </OwlCarousel>
                        </Grid>
                    </Grid>

                </Grid>
            </Container>
            <div className='bg_overlay'></div>
        </div>
    )
}

export default React.memo(OurExperience);