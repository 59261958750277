import actions from "./actions";

const initialState = {
  flightList: [],
  flightTo: [],
  From: "",
  To: "",
  Travellers: "",
  Class: "",
  cities: [],
  flightListDetails: null,
  fromCities: [],
  toCities: [],
  SearchFlight: null,
  tempFlightDetails: null,
  fareRulesFlight: '',
  fareMethod: null,
  adultTravellerData: [],
  childTravellerData: [],
  infantTravellerData: [],
  travellerData: [],
  bookingFareSource: '',
  bookingResponse: '',
  ticketOrderResponse: [],
  ticketOrderStatus: [],
  ticketTripDetails: [],
  pnrTicketCancel: [],
  pnrTicketCancelOtpConfim: [],
  resultData: [],
  pnr: '',
  flightLoader: false,
  filterData: { flightStop: ["Any"], flightComp: ["All"], flightPrice: 100000 },
  isRefundable: '',
  flightSessionReload: false,
  getFlightCoupon: [],
  flightSessionId: '',
  flightFilterResult:null,
};

const flightReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FLIGHT_SESSION_RELOAD:
      return {
        ...state,
        flightSessionReload: action.payload
      }
    case actions.SET_FLIGHT_LOADER:
      return {
        ...state,
        flightLoader: action.payload
      }
    case actions.SET_RESULT_FLIGHT:
      return {
        ...state,
        resultData: action.payload
      }
    case actions.SET_ALL_FLIGHT:
      return {
        ...state,
        flightList: action.payload
      }
    case actions.SET_ALL_CITIES:
      return {
        ...state,
        cities: action.payload
      }
    case actions.SET_FROM_CITIES:
      return {
        ...state,
        fromCities: action.payload
      }
    case actions.SET_TO_CITIES:
      return {
        ...state,
        toCities: action.payload
      }
    case actions.UPDATE_FLIGHT_LIST_DETAILS:
      return {
        ...state,
        flightListDetails: action.payload
      }
    case actions.SET_ALL_FILTER:
      return {
        ...state,
        From: action.payload.From,
        To: action.payload.To,
        Travellers: action.payload.Travellers,
        Class: action.payload.Class,
      }
    case actions.SET_SEARCH_FLIGHT:
      return {
        ...state,
        SearchFlight: action.payload
      }
    case actions.SET_FILTER_FLIGHT:
      return {
        ...state,
        filterData: action.payload
      }
    case actions.GET_ALL_FLIGHT:
      return {
        ...state,
        tempFlightDetails: action.flightData
      }
    case actions.SET_FARE_RULES:
      return {
        ...state,
        fareRulesFlight: action.payload
      }
    case actions.SET_FARE_RULES_LOADER:
      return {
        ...state,
        fareRulesLoader: action.payload
      }
    case actions.SET_VALIDATE_FARE_METHOD:
      return {
        ...state,
        fareMethod: action.payload
      }
    case actions.SET_ADULT_TRAVELLER_DATA:
      return {
        ...state,
        adultTravellerData: action.payload
      }
    case actions.SET_CHILD_TRAVELLER_DATA:
      return {
        ...state,
        childTravellerData: action.payload
      }
    case actions.SET_INFANT_TRAVELLER_DATA:
      return {
        ...state,
        infantTravellerData: action.payload
      }

    case actions.SET_BOOKIG_TRAVELLER_DATA:
      return {
        ...state,
        travellerData: action.payload
      }
    case actions.SET_BOOKIN_FARE_SOURCE_METHOD:
      return {
        ...state,
        bookingFareSource: action.payload
      }
    case actions.SET_BOOKIG_RESPONSE_DATA:
      return {
        ...state,
        bookingResponse: action.payload
      }
    case actions.SET_TICKET_ORDER_DATA:
      return {
        ...state,
        ticketOrderResponse: action.payload
      }
    case actions.SET_BOOKING_CONFIRM:
      return {
        ...state,
        bookingConfirm: action.payload
      }
    case actions.SET_TRIP_DETAILS:
      return {
        ...state,
        ticketTripDetails: action.payload
      }
    case actions.SET_TICKET_ORDER_STATUS:
      return {
        ...state,
        ticketOrderStatus: action.payload
      }
    case actions.SET_PNR_TICKET_CANCEL:
      return {
        ...state,
        pnrTicketCancel: action.payload
      }
    case actions.SET_PNR_TICKET_OTP_CONFIRM:
      return {
        ...state,
        pnrTicketCancelOtpConfim: action.payload
      }
    case actions.SET_PNR:
      return {
        ...state,
        pnr: action.payload
      }
    case actions.SET_FLIGHT_IS_REFUNDABLE:
      return {
        ...state,
        isRefundable: action.payload
      }
    case actions.RESET_INITIAL_STATE:
      return {
        ...initialState
      }
    case actions.SET_FLIGHT_COUPON:
      return {
        ...state,
        getFlightCoupon: action.payload
      }
      case actions.SET_FLIGHT_FILTER:
        return {
          ...state,
          flightFilterResult: action.payload
        }
    default:
      return state;
  }
};

export default flightReducer;