import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import cardImg from '../../asset/images/travel-1.png'
import avatarImg from '../../asset/images/avatar.jpg'
import { AvatarGroup, Box } from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { Link } from 'react-router-dom'

export default function BlogVerticalCard() {
    return (
        <Box>
            <Link to='/blog-detail'>
                <Card sx={{ maxWidth: 345 }} className='blog_ver_card'>
                    <CardHeader
                        avatar={
                            <Avatar sx={{ bgcolor: red[500] }} src={avatarImg} aria-label="recipe" />

                        }
                        action={
                            <IconButton aria-label="settings">
                                <MoreVertIcon />
                            </IconButton>
                        }
                        title="Mark Antony"
                        subheader="25 Blogs"
                    />
                    <Box className='verCard_img_sec'>
                        <CardMedia
                            className='verCard_img'
                            component="img"
                            height="194"
                            image={cardImg}
                            alt="Paella dish"
                        />
                        <span className='verCard_tag'>Travel</span>
                    </Box>
                    <Box className='horizontal_bager_td '>

                        <Box className='horiCard_like_share_icon '>
                            <CardActions disableSpacing>
                                <IconButton aria-label="add to favorites">
                                    <FavoriteIcon className='red' />
                                </IconButton>
                                <IconButton aria-label="share">
                                    <ShareIcon />
                                </IconButton>
                            </CardActions>
                        </Box>
                        <LocalOfferIcon />

                    </Box>
                    <Box className='horizontal_bager_name'>
                        <Box direction="row" className='horiCard_like_share'>
                            <Typography variant='h5'><Typography variant='span'>Liked by</Typography>Muthu and 52 others</Typography>
                        </Box>
                        <AvatarGroup >
                            <Avatar alt="Remy Sharp" src={avatarImg} />
                            <Avatar alt="Travis Howard" src={avatarImg} />
                            <Avatar alt="Cindy Baker" src={avatarImg} />
                        </AvatarGroup>
                    </Box>
                    <Typography variant='h2' className='verCard_title'>We found a paradise in koh change island thailand</Typography>
                    <Typography variant="span" className='verCard_content'>
                        This impressive paella is a perfect party dish and a fun meal to cook
                        together with your guests.
                    </Typography>
                </Card>
            </Link>
        </Box>
    );
}
