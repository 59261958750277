import { Avatar, Box, Button, Container, Typography } from '@material-ui/core'
import React from 'react'
import Header from '../common/header'
import Grid from '@mui/material/Grid';
import { AvatarGroup, Stack } from '@mui/material';
import avatarImg from '../../asset/images/avatar.jpg'
import ShareIcon from '@mui/icons-material/Share';
import FavoriteIcon from '@mui/icons-material/Favorite';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import blogImage from '../../asset/images/blog-1.png'
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import PinterestIcon from '@material-ui/icons/Pinterest';
import { useForm } from "react-hook-form";
import BlogVerticalCard from './BlogVerticalCard';
import Footer from '../common/footer';

const optionsBloggers = {
    margin: 10,
    responsiveClass: true,
    nav: true,
    loop: true,
    dots: false,
    autoplay: false,
    navText: ["<<", ">>"],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        700: {
            items: 1,
        },
        1000: {
            items: 1,
        }
    },
};

const optionsRelatedBlog = {
    margin: 10,
    responsiveClass: true,
    nav: false,
    loop: true,
    dots: false,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 2,
        },
        1000: {
            items: 3,
        }
    },
};

export const BlogDetail = () => {

    const { register } = useForm();

    return (
        <Box>
            <Header />
            <Box className='blog_detail_section'>
                <Box className='blog_detail_banner'>
                    <Container>
                        <Box className='blog_detail_banner_inner'>
                            <Box className='blog_detail_heading'>
                                <Typography variant='h2'>
                                    traveller visiting ice cave with amazing eye-catching scenes
                                </Typography>
                                <Box className='catogories_btns'>
                                    <Button type='button' className='catogories_btn_1'>Adventure</Button>
                                    <Button type='button' className='catogories_btn_2'>Heritage</Button>
                                    <Button type='button' className='catogories_btn_3'>Holiday</Button>
                                </Box>
                            </Box>
                            <Box className='blog_user_detail'>
                                <Box className='horizontal_bager_td bt-l'>
                                    <Stack direction="row" spacing={2} className='user_profile_avatar'>
                                        <Avatar alt="Remy Sharp" src={avatarImg} />
                                        <Box className='blogHorizontal_card_name'>
                                            <Typography variant='h6'>Bloger Name</Typography>
                                            <Typography variant='span' className='date_txt'>27 august</Typography>
                                        </Box>
                                    </Stack>

                                    <Box className='blog_details_userDetails'>
                                        <Box direction="row" className='horiCard_like_share'>
                                            <Typography variant='h5'><Typography variant='span'>Liked by</Typography>Muthu and 52 others</Typography>
                                        </Box>
                                        <AvatarGroup >
                                            <Avatar alt="Remy Sharp" src={avatarImg} />
                                            <Avatar alt="Travis Howard" src={avatarImg} />
                                            <Avatar alt="Cindy Baker" src={avatarImg} />
                                        </AvatarGroup>
                                        <Box className='horiCard_like_share_icon '>
                                            <FavoriteIcon />
                                            <ShareIcon />
                                        </Box>
                                    </Box>


                                </Box>
                            </Box>
                        </Box>
                    </Container>
                </Box>
                <Box className='blog_details_content_section'>
                    <Container>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box className='blog_details_content_slider'>
                                    <OwlCarousel className="owl-theme bloggerSlider blogDetailSlider" {...optionsBloggers}>
                                        <Box className="item blgDetail_banner_inner">
                                            <img src={blogImage} alt='img' />
                                        </Box>
                                    </OwlCarousel>
                                    <Box className='blogDetailSlider_content'>
                                        <Typography variant='span'>
                                            Lorem Ipsum is simply  when an unknown printer took a galley of
                                            type and scrambled it to make a type specimen book. dummy text of the printing and typesetting
                                            industry. Lorem Ipsum has been the industry's standard dummy text
                                            ever since the 1500s, when an unknown printer took a galley of
                                            type and scrambled it to make a type specimen book. It has
                                            survived not only five centuries,
                                        </Typography>
                                        <Typography variant='span'>
                                            Lorem Ipsum is simply  when an unknown printer took a galley of
                                            type and scrambled it to make a type specimen book. dummy text of the printing and typesetting
                                            industry. Lorem Ipsum has been the industry's standard dummy text
                                            ever since the 1500s, when an unknown printer took a galley of
                                            type and scrambled it to make a type specimen book. It has
                                            survived not only five centuries,
                                        </Typography>
                                        <Typography variant='h5'>Why integrate Side projects?</Typography>
                                        <Typography variant='span'>
                                            Lorem Ipsum is simply  when an unknown printer took a galley of
                                            type and scrambled it to make a type specimen book. dummy text of the printing and typesetting
                                            industry. Lorem Ipsum has been the industry's standard dummy text
                                            ever since the 1500s, when an unknown printer took a galley of
                                            type and scrambled it to make a type specimen book. It has
                                            survived not only five centuries,
                                        </Typography>
                                        <Box className='blogDetailSlider_quote'>
                                            <FormatQuoteIcon />
                                            <Typography variant='span'>
                                                Lorem Ipsum is simply  when an unknown printer took a galley of
                                                type and scrambled it to make a type specimen book. dummy text of the printing and typesetting
                                                industry. Lorem Ipsum has been the industry's standard dummy text
                                                ever since the 1500s,
                                            </Typography>
                                        </Box>
                                        <Box className='blogDetailSlider_imageContent'>
                                            <Grid container spacing={2}>
                                                <Grid item xs={8}>
                                                    <Box className='blogDetailSlider_conten'>
                                                        <Typography variant='h5'>Why integrate Side projects?</Typography>
                                                        <Typography variant='span'>
                                                            Lorem Ipsum is simply  when an unknown printer took a galley of
                                                            type and scrambled it to make a type specimen book. dummy text of the printing and typesetting
                                                            industry. Lorem Ipsum has been the industry's standard dummy text
                                                            ever since the 1500s, when an unknown printer took a galley of
                                                            type and scrambled it to make a type specimen book. It has
                                                            survived not only five centuries,
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Box className='blogDetailSlider_image'>
                                                        <img src={blogImage} alt='img' />
                                                    </Box>
                                                </Grid>
                                            </Grid>


                                        </Box>
                                        <Box className='blogDetailSlider_social'>
                                            <Box className='socialMedias_list'>
                                                <Typography variant='h6'>Share : </Typography>
                                                <FacebookIcon className='fb_cb' />
                                                <TwitterIcon className='twit_cb' />
                                                <LinkedInIcon className='link_cb' />
                                                <PinterestIcon className='pin_cb' />
                                            </Box>
                                            <Stack direction="row" spacing={2} className='user_profile_avatar'>
                                                <Avatar alt="Remy Sharp" src={avatarImg} />
                                                <Box className='blogHorizontal_card_name'>
                                                    <Typography variant='h6'>Bloger Name</Typography>
                                                    <Typography variant='span' className='date_txt'>250 Blogs</Typography>
                                                </Box>
                                            </Stack>
                                        </Box>
                                        <Box className='blogDetails_commend_form'>
                                            <form>
                                                <textarea
                                                    name="commends"
                                                    placeholder='Your comments'
                                                    type="text"
                                                    rows="6"
                                                    id="CommentsOrAdditionalInformation"
                                                    {...register('requirements')} className='form-control'
                                                >
                                                </textarea>
                                                <Button className='book_tick pop_con'>POST COMMENTS</Button>
                                            </form>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                    <Box className='blogDetail_relatedBlogs'>
                        <Container>
                            <Box className='recent_blog_head'>
                                <Typography variant='h3'>destination blog's</Typography>
                                <Button className='book_tick blog_more'>View All</Button>
                            </Box>
                            <Box className='relatedBlogs_slider'>
                                <OwlCarousel className="owl-theme relatedBlogs" {...optionsRelatedBlog}>
                                    <Box className="item bloggerInner">
                                        <BlogVerticalCard />
                                    </Box>
                                </OwlCarousel>
                            </Box>
                        </Container>
                    </Box>
                </Box>
            </Box>
            <Footer />
        </Box>
    )
}
