import React from 'react'
import { useState } from 'react'
import { Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { useEffect } from 'react'
import userActions from '../../redux/user/actions'
import commonActions from '../../redux/common/actions'
import loaderGifImg from "../../asset/loader_hotel.gif";

export const HotelGuestCancel = () => {

    const dispatch = useDispatch()
    const history = useNavigate()
    const { supplierConfirmationNumber } = useSelector((state) => state.userReducer)
    const { loading } = useSelector(
        (state) => state.commonReducer
    )
    const [btnDis, setBtnDis] = useState(false)

    const OnSendOtp = (e) => {
        e.preventDefault()
        dispatch({ type: commonActions.SET_LOADER, payload: true });
        dispatch({
            type: userActions.GET_HOTEL_BOOKINGS_GUEST_CANCEL_REG, payload: {
                supplierConfirmationNum: supplierConfirmationNumber
            },
            history: history
        })
    }

    useEffect(() => {
        if (supplierConfirmationNumber === '') {
            setBtnDis(true)
        } else {
            setBtnDis(false)
        }
    }, [supplierConfirmationNumber])

    return (
        <>
            {loading ?
                <div>
                    <div className='blur_bg'></div>
                    <div className='loaderGif'>
                        <img src={loaderGifImg} alt='' />
                    </div>
                </div>
                :
                null
            }
            <div className='pnr_cancel_sec hotel_scn_cancel'>

                <div className='pnr_cancel_card'>
                    <form onSubmit={OnSendOtp}>
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Supplier Confirmation Number</label>
                            <input type="text" className="form-control" placeholder="Enter your SCN" name='pnrNo' value={supplierConfirmationNumber} required='required' onChange={(e) => dispatch({ type: userActions.SET_SCN_NUMBER, payload: e.target.value })} />
                            <small id="emailHelp" className="form-text text-muted">Enter the ticket SCN to send otp your register mail i'd</small>
                        </div>
                        <Button disabled={btnDis} type="submit">Send OTP</Button>
                    </form>
                </div>
            </div>
        </>
    )
}
