import { Button, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Form } from 'react-bootstrap';
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Add, FlightTakeoff, Remove, Search } from "@material-ui/icons";
import "../../../src/components/home/home.css";
import { useDispatch, useSelector } from "react-redux";
import flightActions from "../../redux/flight/actions";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { numberRestriction } from './../../utils/validate';
import { useNavigate } from "react-router-dom";
import moment from "moment";
import loaderGifImg from '../../asset/loader_flight.gif';
import axios from "axios";
import { API_URL } from "../../utils/constant";
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DatePicker } from 'antd';
import { store } from './../../redux/store';
import flightUp from './../../asset/icons/flight-up.svg';
import flightDown from './../../asset/icons/flight-down.svg';
import DropDown from '../../utils/flightDownDropdown';
import CalenderGray from '../../asset/icons/calender-svgrepo-com.svg';

const validationSchema = yup.object().shape({
    airport_from_code: yup.object()
        .required('From is required'),
    airport_to_code: yup.object()
        .required('To is required'),
    departure_date: yup.string()
        .required('Depart is required'),
    return_date: yup.string().nullable(),
    adult_flight: yup.number()
        .min(1, "Enter min 1")
        .max(250, "Enter min 250")
        .typeError("Min value is required")
});

const currenciesClass = [
    {
        value: "Economy",
        label: "Economy",
    },
    {
        value: "Business",
        label: "Business",
    },
    {
        value: "Premium",
        label: "Premium",
    },
];


function FlightTrip(props) {

    const dispatch = useDispatch();
    const history = useNavigate();
    const { register, handleSubmit, reset, setValue, control, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });
    const { SearchFlight, cities } = useSelector(
        (state) => state.flightReducer
    );
    const { loading } = useSelector(
        (state) => state.commonReducer
    );
    const dateFormat = 'YYYY/MM/DD';

    const [addnewCity, setaddnewCity] = useState([]);
    const [returnEnable, setReturnEnable] = useState(true);
    const [radiobtn, setradiobtn] = useState('OneWay');
    const [className, setClassName] = useState(SearchFlight?.class ? SearchFlight?.class : 'Economy');
    const [depDate, setDepDate] = useState({ date: moment(new Date()), focus: false });
    const [retDate, setRetDate] = useState({ date: moment(new Date()).add(1, 'day'), focus: false });
    const [selectedCity, setSelectedCity] = useState({
        from: null,
        to: null
    });

    const handleAddcity = () => {
        setaddnewCity([...addnewCity,
        { newCity: "" }
        ])
    };

    const handleRemovecity = (index) => {
        const addnewCitylist = [...addnewCity];
        addnewCitylist.splice(index, 1)
        setaddnewCity(addnewCitylist);
    };

    const onSubmit = (data) => {
        let datas = {
            journey_type: radiobtn,
            airport_from_code: data.airport_from_code.airport_code,
            airport_to_code: data.airport_to_code.airport_code,
            airport_from: data.airport_from_code,
            airport_to: data.airport_to_code,
            departure_date: moment(data.departure_date).format('YYYY-MM-DD'),
            return_date: radiobtn === "Return" ? moment(data.return_date).format('YYYY-MM-DD') : undefined,
            adult_flight: data.adult_flight,
            child_flight: data.child_flight,
            class: className,
            infant_flight: data.infant_flight,
            dep_date: depDate,
            req_date: retDate
        };
        dispatch({ type: flightActions.SET_SEARCH_FLIGHT, payload: datas });
        dispatch({ type: flightActions.GET_ALL_FLIGHT, flightData: datas, history: history });

    };

    const handleJurnytype = (e) => {
        setradiobtn(e.target.value)
        setReturnEnable(e.target.value === "OneWay");
    };

    const loadFromOptions = async (inputValue, callback) => {
        if (inputValue.trim().length > 2) {
            var form_data = new FormData();
            form_data.append('name', inputValue.trim());
            const result = await axios.post(
                `${API_URL}/getAirportNameSearch`,
                form_data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
            );
            callback(result.data.message.filter(val => val.airport_code !== selectedCity.to))
        } else {
            callback(null)

        }
    };

    const loadToOptions = async (inputValue, callback) => {
        if (inputValue.trim().length > 2) {
            var form_data = new FormData();
            form_data.append('name', inputValue.trim());
            const result = await axios.post(
                `${API_URL}/getAirportNameSearch`,
                form_data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
            );
            callback(result.data.message.filter(val => val.airport_code !== selectedCity.from))
        } else {
            callback(null)

        }
    };
    const DropdownUpIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <img width="30" src={flightUp} />
            </components.DropdownIndicator>
        );
    };
    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <img width="30" src={flightUp} />
            </components.DropdownIndicator>
        );
    };

    useEffect(() => {
        if (SearchFlight?.class !== undefined) {
            setClassName(SearchFlight?.class)
        }
        reset({
            departure_date: depDate.date,
            return_date: retDate.date,
        })
    }, [SearchFlight])


    useEffect(() => {
        if (SearchFlight) {
            reset({
                'journey_type': SearchFlight?.journey_type,
                'airport_from_code': SearchFlight?.airport_from,
                'airport_to_code': SearchFlight?.airport_to,
                'departure_date': SearchFlight?.departure_date,
                'return_date': SearchFlight?.return_date,
                'adult_flight': SearchFlight?.adult_flight,
                'child_flight': SearchFlight?.child_flight,
                'infant_flight': SearchFlight?.infant_flight
            });
            let tempDepDate = {};
            let tempReqDate = {};
            tempDepDate["date"] = moment(SearchFlight?.departure_date);
            tempReqDate["date"] = moment(SearchFlight?.return_date);
            setDepDate(tempDepDate);
            setRetDate(tempReqDate);
            // if (SearchFlight?.dep_date) {
            //     reset({
            //         'journey_type': SearchFlight?.journey_type,
            //         'airport_from_code': SearchFlight?.airport_from,
            //         'airport_to_code': SearchFlight?.airport_to,
            //         'departure_date': SearchFlight?.departure_date,
            //         'adult_flight': SearchFlight?.adult_flight,
            //         'child_flight': SearchFlight?.child_flight,
            //         'infant_flight': SearchFlight?.infant_flight
            //     });
            //     let tempDepDate = SearchFlight.dep_date;
            //     let tempReqDate = SearchFlight.req_date;
            //     tempDepDate["date"] = moment(SearchFlight.dep_date.date);
            //     tempReqDate["date"] = moment(SearchFlight.req_date.date);
            //     setDepDate(tempDepDate);
            //     setRetDate(tempReqDate);
            // }
        }
    }, [SearchFlight]);


    return (
        <div>
            <div className=''>
                <div className="flight_trip_sec" sx={{ textAlign: loading ? 'center' : 'inherit' }}>
                    {loading ?
                        <div className="search_flight_gif">
                            <div className='loaderGif'>
                                <img src={loaderGifImg} alt='' />
                            </div>
                        </div> :
                        <div>
                            <form onSubmit={handleSubmit(onSubmit)}  >
                                <div className="trip-in padd_horz">
                                    <div className="trip_select">
                                        <FormControl>
                                            <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="journey_type" isinvalid={(!!errors.journey_type).toString()} onChange={handleJurnytype} onKeyUp={handleJurnytype} className="trip_rad" defaultValue={SearchFlight?.journey_type ? SearchFlight?.journey_type : 'OneWay'}>
                                                <FormControlLabel value="OneWay" control={<Radio />} label={<Typography variant="body2">One way</Typography>} />
                                                <FormControlLabel value="Return" control={<Radio />} label={<Typography variant="body2">Round trip</Typography>} />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    <Box>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                                <div className="form-group">
                                                    <label className='label-heading' htmlFor="exampleInputEmail1">From</label>
                                                    <Controller
                                                        control={control}
                                                        render={({ field }) => {
                                                            const handleSelectChange = (selectedOption) => {
                                                                setSelectedCity({ ...selectedCity, fromCity: selectedOption?.city, fromAirportName: selectedOption?.airport_name, from: selectedOption?.airport_code })
                                                                field.onChange({ airport_code: selectedOption?.airport_code, airport_name: selectedOption?.airport_name, city: selectedOption?.city });
                                                            };
                                                            return (
                                                                <div className='d-Flex border_bottom'>
                                                                    <img src={flightUp} width="30" alt='flight Up Icon' />
                                                                    <AsyncSelect
                                                                        {...field}
                                                                        className="w-100"
                                                                        inputRef={field.ref}
                                                                        defaultOptions={selectedCity?.fromCity && [{ city: selectedCity.fromCity, airport_name: selectedCity.fromAirportName, airport_code: selectedCity.from }]}
                                                                        name={"airport_from_code"}
                                                                        getOptionLabel={(e) => e.city + '-' + e.airport_name + '(' + e.airport_code + ')'}
                                                                        getOptionValue={(e) => e.airport_code + '-' + e.airport_name}
                                                                        loadOptions={loadFromOptions}
                                                                        onChange={handleSelectChange}
                                                                    // components={{ DropdownIndicator }}
                                                                    />
                                                                </div>
                                                            );
                                                        }}
                                                        name="airport_from_code"
                                                    />
                                                </div>
                                                <span id="emailHelp" className="form-text text-muted error">{errors.airport_from_code?.message}</span>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                                <div className="form-group">
                                                    <label className='label-heading' htmlFor="exampleInputEmail1">To</label>

                                                    <Controller
                                                        control={control}
                                                        render={({ field }) => {
                                                            const handleSelectChange = (selectedOption) => {
                                                                setSelectedCity({ ...selectedCity, ToCity: selectedOption?.city, toAirportName: selectedOption?.airport_name, to: selectedOption?.airport_code })
                                                                field.onChange({ airport_code: selectedOption?.airport_code, airport_name: selectedOption?.airport_name, city: selectedOption?.city });
                                                            };
                                                            return (
                                                                <div className='d-Flex border_bottom'>
                                                                    <img src={flightDown} width="30" alt='flight Down Icon' />
                                                                    <AsyncSelect
                                                                        {...field}
                                                                        className="w-100"
                                                                        inputRef={field.ref}
                                                                        defaultOptions={selectedCity?.ToCity && [{ city: selectedCity.ToCity, airport_name: selectedCity.toAirportName, airport_code: selectedCity.to }]}
                                                                        name={"airport_to_code"}
                                                                        getOptionLabel={(e) => e.city + '-' + e.airport_name + '(' + e.airport_code + ')'}
                                                                        getOptionValue={(e) => e.airport_code + '-' + e.airport_name}
                                                                        loadOptions={loadToOptions}
                                                                        onChange={handleSelectChange}
                                                                        components={{ DropDown }}
                                                                    />
                                                                </div>
                                                            );
                                                        }}
                                                        name="airport_to_code"
                                                    />
                                                </div>
                                                <span id="emailHelp" className="form-text text-muted error">{errors.airport_to_code?.message}</span>
                                            </div>
                                            {addnewCity.map((val, index) =>
                                                <div className="col-lg-3 col-md-3 col-sm-6 col-12" key={val}>
                                                    <label className='label-heading' htmlFor="exampleInputEmail1">Add Multi City</label>
                                                    <select
                                                        name="airport_AddmultyTrip_code"
                                                        {...register('airport_AddmultyTrip_code')} isinvalid={(!!errors.airport_AddmultyTrip_code).toString()}
                                                        className="form-select"
                                                        aria-label="Default select example"
                                                    >
                                                        <option>Multi</option>
                                                        {cities.map((option) => (
                                                            <option key={option.value} value={option.value}>
                                                                {option.city}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <button onClick={() => handleRemovecity(index)} className='remove_slight' type="button" name="Remove"><Remove /></button>
                                                    <span id="emailHelp" className="form-text text-muted error">{errors.airport_AddmultyTrip_code?.message}</span>

                                                </div>
                                            )}
                                            {radiobtn === "MultiCity" ?
                                                <button className="addmore_city_btns" onClick={handleAddcity} type="button" name="Add"><Add /></button> :
                                                <></>
                                            }
                                            <div className="col-lg-3 col-md-3 col-sm-6 col-12 flight_date_picker">
                                                <Form.Group controlId="departure_date">
                                                    <label className='label-heading' htmlFor="exampleInputEmail1">Depart on</label>
                                                    <div className="d-Flex border_bottom">
                                                        <img src={CalenderGray} alt='calender icon' className='calender_icon' />
                                                        <DatePicker
                                                            allowClear={false}
                                                            disabledDate={current => {
                                                                return current < moment(new Date()).subtract(1, "day");
                                                            }}
                                                            onChange={(date) => {
                                                                setValue('return_date', moment(date).add(1, 'day'));
                                                                setRetDate({ ...retDate, date: moment(date).add(1, 'day') });
                                                                setDepDate((p) => ({ ...p, date }));
                                                            }} defaultValue={depDate?.date} format={dateFormat} />
                                                    </div>
                                                </Form.Group>
                                                <span id="emailHelp" className="form-text text-muted error">{errors.departure_date?.message}</span>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                                <Form.Group controlId="departure_date">
                                                    <label className='label-heading' htmlFor="exampleInputEmail1">Return on</label>
                                                    <div className="d-Flex border_bottom">
                                                        <img src={CalenderGray} alt='calender icon' className='calender_icon' />
                                                        <DatePicker
                                                            allowClear={false}
                                                            disabledDate={current => {
                                                                return current < moment(depDate.date);
                                                            }}
                                                            disabled={returnEnable}
                                                            onChange={(date) => {
                                                                setRetDate((p) => ({ ...p, date }));
                                                            }}
                                                            value={retDate?.date}
                                                            format={dateFormat} />
                                                    </div>
                                                </Form.Group>
                                                <span id="emailHelp" className="form-text text-muted error">{errors.return_date?.message}</span>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                                <div className="form-group">
                                                    <label className="label-heading">Adults(12 years+)</label>
                                                    <select className="form-control" defaultValue={SearchFlight ? SearchFlight.adult_flight : 1} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Example : 1" name='adult_flight' onInput={numberRestriction}  {...register('adult_flight')} isinvalid={(!!errors.adult_flight).toString()} >
                                                        <option >Select Adults</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value="6">6</option>
                                                        <option value="7">7</option>
                                                        <option value="8">8</option>
                                                        <option value="9">9</option>
                                                    </select>
                                                </div>
                                                <span id="emailHelp" className="form-text text-muted error">{errors.adult_flight?.message}</span>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                                <label className="label-heading">Children(2-12 years)</label>
                                                <select className="form-control" defaultValue={SearchFlight && SearchFlight.child_flight} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Example : 1" name='child_flight' onInput={numberRestriction}  {...register('child_flight')}>
                                                    <option value="0">Select Children</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                </select>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                                <label className="label-heading">Infant(&lt;2 years)</label>
                                                <select className="form-control" defaultValue={SearchFlight && SearchFlight.infant_flight} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Example : 1" name='child_flight' onInput={numberRestriction}  {...register('infant_flight')}>
                                                    <option value="0">Select Infant</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                </select>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                                <div className="form-group">
                                                    <label className="label-heading">Class</label>
                                                    <select
                                                        placeholder={<FlightTakeoff />}
                                                        name="class"
                                                        defaultValue={className} onChange={(e) => setClassName(e.target.value)} isinvalid={(!!errors.airport_from_code).toString()}
                                                        className="form-select"
                                                        aria-label="Default select example">
                                                        {currenciesClass.map((option) => (
                                                            <option key={option.value} value={option.value}>
                                                                {option.value}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <span id="emailHelp" className="form-text text-muted error">{errors.class?.message}</span>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 flight_search_btn">
                                                <Button type="submit" className="search_btn_banner" variant="outlined" startIcon={<Search />}>
                                                    {props.mode}
                                                </Button>
                                            </div>
                                        </div>
                                    </Box>
                                </div>
                            </form>
                        </div>}
                </div>
            </div>
        </div>
    )
}

export default React.memo(FlightTrip);