import * as React from 'react';
import { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormControl, FormControlLabel, FormGroup, Radio, RadioGroup } from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { Container } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import flightActions from '../../redux/flight/actions';
import axios from 'axios';
import { API_URL } from '../../utils/constant';
import commonActions from '../../redux/common/actions';

// import commonActions from '../../redux/common/actions';

// const marks = [
//   {
//     value: 500,
//     label: '500',
//   },
//   {
//     value: 100000,
//     label: '100000',
//   },
// ];

function FlightFilter(props) {

  const dispatch = useDispatch();
  const { filterData, flightList,flightSessionId,flightFilterResult } = useSelector(state => state.flightReducer);

  var [flightCabin, setFlightCabin] = useState('');
  var [minMaxSlider, setMinMaxSlider] = useState({ MinItem: '', MaxItem: '' })
console.log('flightCabin',flightCabin)

  useEffect(() => {
    axios.post(
      `${API_URL}/filter`,{ 'filter_type': 'hotel' }, {
      headers: {
        accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    }
    ).then((res) => {
      setMinMaxSlider(minMaxSlider = { MinItem: res.data.filter.min, MaxItem: res.data.filter.max })
    }).catch(err => {

    })

  }, []);
  // console.log(filterData)
  const flightDetails = (name, status) => {
  
    let temp = filterData.flightComp;
    if (status) {
      temp = [...temp, name];
    } else {
      temp = filterData.flightComp.filter(el => el !== name);
    }
    let secondTemp = [], temp4 = [];
    let fTemp = temp.map(el => el.toUpperCase());
    console.log('flightList', fTemp)
    flightList.forEach(el => {
      if (fTemp.includes('ALL')) {
        temp4.push(el);
      } else if (el.find(a => (fTemp.includes(a.flightName.toUpperCase())))) {
        temp4.push(el);
      }
    });
    let temp3 = filterData.flightStop.map(el => el.toUpperCase());
    temp4.forEach(el => {
      if ((temp3.includes("NONSTOP") && el.find(val => val.flight_details.every(y => y.totalStops === 0)))) {
        secondTemp.push(el);
      } else if ((temp3.includes("ONESTOP") && el.find(val => val.flight_details.every(y => y.totalStops === 1)))) {
        secondTemp.push(el);
      } else if ((temp3.includes("TWOSTOP") && el.find(val => val.flight_details.every(y => y.totalStops === 2)))) {
        secondTemp.push(el);
      } else if (temp3.includes("ANY")) {
        secondTemp.push(el);
      }
    });
    // dispatch({ type: commonActions.SET_LOADER, payload: true });
    dispatch({ type: flightActions.SET_RESULT_FLIGHT, payload: secondTemp });
    dispatch({ type: flightActions.SET_FILTER_FLIGHT, payload: { ...filterData, flightComp: temp } });
  };

  const flightStopDetails = (name, e) => {
    let temp2 = filterData.flightStop;
    if (e.target.checked) {
      e.target.checked = true;
      temp2 = [...temp2, name];
    } else {
      e.target.checked = false;
      temp2 = temp2.filter(el => el !== name);
    }
    let temp1 = [], secondTemp = [];
    let fTemp = filterData.flightComp.map(el => el.toUpperCase());
    flightList.forEach(el => {
      if (el.find(a => (fTemp.includes(a.flightName.toUpperCase())))) {
        temp1.push(el);
      } else if (fTemp.includes('ALL')) {
        temp1.push(el);
      }
    });
    let temp3 = temp2.map(el => el.toUpperCase());
    console.log(temp1)
    temp1.forEach(el => {
      if ((temp3.includes("NONSTOP") && el.find(a => a.flight_details.every(val => val.totalStops === 0)))) {
        secondTemp.push(el);
      } else if ((temp3.includes("ONESTOP") && el.find(a => a.flight_details.every(val => val.totalStops === 1)))) {
        secondTemp.push(el);
      } else if ((temp3.includes("TWOSTOP") && el.find(a => a.flight_details.every(val => val.totalStops === 2)))) {
        secondTemp.push(el);
      } else if (temp3.includes("ANY")) {
        secondTemp.push(el);
      }
    });
    // dispatch({ type: commonActions.SET_LOADER, payload: true });
    dispatch({ type: flightActions.SET_RESULT_FLIGHT, payload: secondTemp });
    dispatch({ type: flightActions.SET_FILTER_FLIGHT, payload: { ...filterData, flightStop: temp2 } });
  };

  const flightCabinDetails = (e) => {
    let temp = e.target.value;
    setFlightCabin(e.target.value);
    props.handleCabinChange(temp);
  };

  const flightPriceDetails = (e,type) => {
    if (type === "price") {
      dispatch({
        type: flightActions.SET_FLIGHT_FILTER, payload: {
          sessionId: flightSessionId,
          maxResult: 100000,
          filters: {
            ...flightFilterResult?.filters,
            price: {
              min: e.target.value[0],
              max: e.target.value[1]
            }
          }
        }
      });
      dispatch({
        type: flightActions.GET_FLIGHT_FILTER, payload: {
          sessionId: flightSessionId,
          maxResult: 100000,
          filters: {
            ...flightFilterResult?.filters,
            price: {
              min: e.target.value[0],
              max: e.target.value[1]
            }
          }
        }
      })
    }
    let temp = [], secondTemp = [];
    let fTemp = filterData.flightComp.map(el => el.toUpperCase());
    flightList.forEach(el => {
      // if ((fTemp.includes(el.flightName.toUpperCase()))) {
      //   temp.push(el);
      // } else if (fTemp.includes('ALL')) {
      //   temp.push(el);
      // }
    });
    temp.forEach(el => {
      if ((filterData.flightStop.includes("NONSTOP") && el.find(a => a.flight_details.every(val => val.totalStops === 0)))) {
        secondTemp.push(el);
      } else if ((filterData.flightStop.includes("ONESTOP") && el.find(a => a.flight_details.every(val => val.totalStops === 1)))) {
        secondTemp.push(el);
      } else if ((filterData.flightStop.includes("TWOSTOP") && el.find(a => a.flight_details.every(val => val.totalStops === 2)))) {
        secondTemp.push(el);
      } else if (filterData.flightStop.includes("ANY")) {
        secondTemp.push(el);
      }
    });
    // dispatch({ type: commonActions.SET_LOADER, payload: true });
    dispatch({ type: flightActions.SET_RESULT_FLIGHT, payload: secondTemp.filter(val => parseInt(val.totalFare) > 0 && parseInt(val.totalFare) <= parseInt(e.target.value)) });
    dispatch({ type: flightActions.SET_FILTER_FLIGHT, payload: { ...filterData, flightPrice: e.target.value } });
  }

  useEffect(() => {
    setFlightCabin(props?.SearchFlight?.class);
  }, [props]);

  return (
    <div>
      <Container>
        {/* <div className='filter_total_price'>
          <Typography className='tab_td'>Price</Typography>
          <Box>
            <Slider
              aria-label="Always visible"
              defaultValue={filterData.flightPrice}
              min={500}
              max={100000}
              onChange={flightPriceDetails}
              step={500}
              marks={marks}
              valueLabelDisplay="auto"
            />
          </Box>
        </div> */}
        <div className='filter_total_price'>
          {/* <Box className="border-top-gray py-3">
            <Typography className='tab_td'>Max Price</Typography>
            <div className='mr-1'>
              <Slider
                aria-label="Always visible"
                min={parseInt(minMaxSlider.MinItem)}
                max={parseInt(minMaxSlider.MaxItem)}
                onChange={flightPriceDetails}
                step={500}
                // marks={marks}ZZZ
                defaultValue={filterData.flightPrice > 500 ? filterData.flightPrice : 500}
                valueLabelDisplay="auto"
              />
            </div>
          </Box> */}
          <Box className="border-top-gray py-3">
            <Typography className='tab_td'>Price</Typography>
            <div className='mr-1'>
              <Slider
                aria-label="Always visible"
                onChange={(e) => (flightPriceDetails(e, 'price'), 400)}
                min={parseInt(minMaxSlider.MinItem)}
                max={parseInt(minMaxSlider.MaxItem)}
                step={20}
                // marks={hotelPrice}
                defaultValue={[flightFilterResult?.filters?.min ? flightFilterResult?.filters?.min : 0, flightFilterResult?.filters?.max ? flightFilterResult?.filters?.max : 200]}
                valueLabelDisplay='auto'
              />
              <div className='dis_flex'>
                <span className='minmax_title'>Min: {parseInt(minMaxSlider.MinItem)}</span>
                <span className='minmax_title'>Max: {parseInt(minMaxSlider.MaxItem)} </span>
              </div>
            </div>
          </Box>
        </div>
      </Container>
      <div className='accordian_inner'>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className='tab_td'>Airlines</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <FormGroup>
              <FormControlLabel control={<Checkbox defaultChecked={true} />} label="All" value="All" onChange={(e) => flightDetails('All', e.target.checked)} />
              <FormControlLabel control={<Checkbox />} label="Spicejet" value="Spicejet" onChange={(e) => flightDetails("Spicejet", e.target.checked)} />
              <FormControlLabel control={<Checkbox />} label="Indigo Airlines" value="Indigo Airlines" onChange={(e) => flightDetails("Indigo Airlines", e.target.checked)} />
              <FormControlLabel control={<Checkbox />} label="Air India" value="Air India" onChange={(e) => flightDetails("Air India", e.target.checked)} />
              <FormControlLabel control={<Checkbox />} label="Vistara" value="Vistara" onChange={(e) => flightDetails("Vistara", e.target.checked)} />
            </FormGroup>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className='tab_td'>Cabin</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                // value={flightCabin || ''}
                onChange={flightCabinDetails}
              >
                <FormControlLabel value="Business" control={<Radio />} label="Business Class" />
                <FormControlLabel value="Economy" control={<Radio />} label="Economy Class" />
                <FormControlLabel value="Premium" control={<Radio />} label="Premium Class" />
              </RadioGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
        <Accordion >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className='tab_td'>Stops</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <FormGroup>
              <FormControlLabel control={<Checkbox defaultChecked={true} />} value={"Any"} label="Any" onChange={(e) => flightStopDetails('Any', e)} />
              <FormControlLabel control={<Checkbox />} value={"Nonstop"} label="Non-stop" onChange={(e) => flightStopDetails('Nonstop', e)} />
              <FormControlLabel control={<Checkbox />} value={"OneStop"} label="1 Stop" onChange={(e) => flightStopDetails('OneStop', e)} />
              <FormControlLabel control={<Checkbox />} value={"TwoStop"} label="2 Stop" onChange={(e) => flightStopDetails('TwoStop', e)} />
            </FormGroup>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  )
}

export default React.memo(FlightFilter);