import { Button } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import './user.css'
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import HotelIcon from '@mui/icons-material/Hotel';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import userActions from '../../redux/user/actions';
import commonActions from '../../redux/common/actions';
import Modal from 'react-bootstrap/Modal';
import OTPInput from "otp-input-react";
import notFoundImage from '../../asset/hotel_booking_not.gif'

const HotelUpcomingBooking = () => {

    const dispatch = useDispatch()
    const history = useNavigate()
    const { myHotelBookings } = useSelector((state) => state.userReducer)

    const [show, setShow] = useState(false);
    const [otp, setOtp] = useState("");

    const OnCancelBooking = (val, index) => {
        dispatch({ type: commonActions.SET_LOADER, payload: true });
        setShow(index)
        dispatch({
            type: userActions.GET_HOTEL_BOOKINGS_CANCEL_REQUEST, payload: {
                supplierConfirmationNum: val.supplierConfirmationNum,
                referenceNum: val.referenceNum,
            }
        })
    }

    const handleClose = () => setShow(false);

    const confirmOtp = (e, val) => {
        e.preventDefault()
        dispatch({ type: commonActions.SET_LOADER, payload: true });
        dispatch({
            type: userActions.GET_HOTEL_BOOKINGS_CANCEL_VERIFY, payload: {
                supplierConfirmationNum: val.supplierConfirmationNum,
                referenceNum: val.referenceNum,
                OTP: otp
            }
        })
        setShow(false)
        dispatch({ type: userActions.GET_MY_HOTEL_BOOKINGS, payload: '' });
        setOtp("");
    }

    const ViewTicket = (val, index) => {
        let temp = {
            supplierConfirmationNum: val.supplierConfirmationNum,
            referenceNum: val.referenceNum,
        }
        dispatch({ type: commonActions.SET_LOADER, payload: true });
        dispatch({ type: userActions.GET_MY_HOTEL_BOOKING_DETAILS, payload: temp, history: history })
    }

    useEffect(() => {
        dispatch({ type: commonActions.SET_LOADER, payload: true });
        dispatch({ type: userActions.GET_MY_HOTEL_BOOKINGS, payload: '' })
    }, [])

    return (
        <div className='upcoming_trips hotel_trip'>
            {myHotelBookings?.length !== 0 ?
                <div>
                    {myHotelBookings.map((val, index) =>
                        <main className='main_ticket' key={'myHotelBookings' + index}>
                            <section className="flight--general">
                                <div>
                                    <h4><FmdGoodIcon className='hotel_loc' />{val.hotel_name}</h4>
                                </div>
                                <div>
                                    <h4><HotelIcon className='hotel_loc' /> {val.hotel_city}</h4>
                                </div>
                            </section>
                            <section className="flight--TimeInfo">
                                <div>
                                    <h5>Supplier <br />Confirmation No</h5>
                                    <p>{val.supplierConfirmationNum}</p>
                                </div>
                                <div>
                                    <h5>Check In</h5>
                                    <p>{val.checkIn}</p>
                                </div>
                                <div>
                                    <h5>Check Out</h5>
                                    <p>{val.checkOut}</p>
                                </div>
                                <div>
                                    <h5>No Days</h5>
                                    <p>{val.days}</p>
                                </div>
                                <div>
                                    <h5>Booking</h5>
                                    <p>{val.status}</p>
                                </div>
                                <div>
                                    <Button className='view_ticket_flight' onClick={() => ViewTicket(val, index)}>View Ticket</Button>
                                    <Button
                                    
                                    
                                    
                                    ssName='view_ticket red' onClick={() => OnCancelBooking(val, index)}>Cancel Booking</Button>

                                    <Modal show={show === index} centered onHide={handleClose} className='hotel_cancel_model'>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Hotel Booking Cancel</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <form onSubmit={(e) => confirmOtp(e, val)}>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Enter Your OTP</label>
                                                    <OTPInput
                                                        error
                                                        value={otp}
                                                        onChange={setOtp}
                                                        autoFocus
                                                        require
                                                        OTPLength={6}
                                                        otpType="number"
                                                        disabled={false}
                                                        className='otp_field'
                                                    />
                                                </div>
                                                <button disabled={otp.length !== 6} type="submit" className="btn btn-primary">Confirm Cancel</button>
                                            </form>
                                        </Modal.Body>
                                    </Modal>
                                </div>
                            </section>

                        </main>
                    ).reverse()}
                </div>
                :
                <div className='hotel_not_found'>
                    <h3>You Don't have any Upcomming Bookings</h3>
                    <img src={notFoundImage} alt='notFound' />
                    <Link to='/'>Go to booking</Link>
                </div>
            }
        </div>
    )
}

export default React.memo(HotelUpcomingBooking);
